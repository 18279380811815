import { Link } from "react-router-dom";
import { MdDocumentScanner, MdEditDocument, MdOutlineExitToApp, MdSupport } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BiBuilding } from "react-icons/bi";

const MerchantHeader = () => {

    const logout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("jwtToken");
        window.location.href = "/";
    }

    return (
        <div className="d-flex justify-content-between align-items-center py-3 px-1 bg-white" style={{ zIndex: 5, position: "sticky", top: 0 }} id="app-header">
            <Link to="/merchant" className="btn btn-light text-decoration-none d-flex align-items-center gap-1 bg-transparent border-0">
                <div className="d-flex gap-3 align-items-center">
                    {/* <img src="/icon.png" alt="" style={{ height: 50 }} /> */}
                    <div>
                        <h2 className="text-lg-start fs-6 text-uppercase mb-0">{"Loyalty System Suite".toUpperCase()}</h2>
                        <h1 className="text-lg-start fw-bold fs-5 mb-0">Retail Dashboard</h1>
                    </div>
                </div>
            </Link>
            <div className="d-flex gap-3 align-items-center">
                <Link to="/merchant/business" style={{ textDecoration: 'none' }}>
                    <button className="btn btn-light text-decoration-none d-flex align-items-center gap-1 bg-transparent outline-none border-0" >My Business <BiBuilding size={20} /></button>
                </Link>
                <Link to="/merchant/documentation" style={{ textDecoration: 'none' }}>
                    <button className="btn btn-light text-decoration-none d-flex align-items-center gap-1 bg-transparent outline-none border-0">Documentation <IoDocumentTextOutline size={20} /></button>
                </Link>
                <button className="btn btn-light text-decoration-none d-flex align-items-center gap-1 bg-transparent outline-none border-0" onClick={logout}>Logout <MdOutlineExitToApp size={20} /></button>
            </div>
        </div>
    );
}

export default MerchantHeader;