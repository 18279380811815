import React, { useEffect, useState } from 'react'
import AdminHeader from '../../components/AdminHeader'
import AdminNavbar from '../../components/AdminNavbar'
import { useLocation, useNavigate, useParams } from 'react-router'
import { IoAddSharp, IoClose } from 'react-icons/io5'
import config from '../../app-config'
import { ToastContainer, toast } from 'react-toastify';
import Select from '@mui/base/Select';
import Option from '@mui/base/Option';
import AdminLayout from '../../layouts/AdminLayout';

export default function AdminBranchFormPage() {
    const navigate = useNavigate()
    const { state } = useLocation()

    const BRANCH_ID = useParams().id || state?.id
    console.log({ BRANCH_ID })
    const [companyData, setCompanyData] = useState([])
    const [companyServices, setCompanyServices] = useState([])
    const [branchData, setBranchData] = useState(state ? state : {
        name: "",
        company_id: "",
        id: "",
        address_line_1: "",
        address_line_2: "",
        postal_code: "",
        country: "",
        city: "",
        state: "",
        unit_number: "",
        telephone: "",
        email: "",
        fax: "",
        website: "",
        map_link: "",
        operating_hours: [{ day: "Monday", open: "09:00", close: "18:00" }], // {day: "Monday", open: "9:00", close: "18:00"}
        services: [],
        remarks: "",
    })

    const COMPANY_ID = state?.company_id || branchData?.company_id
    const registerBranch = async () => {
        let endpoint = config.endpoint + "/branch/"
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",

            },
            body: JSON.stringify(branchData)
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Success!")
            navigate(-1)
        } else {
            toast.error("Unable to register branch " + response.message)
        }
    }

    const updateBranch = async () => {
        let endpoint = config.endpoint + "/branch/"
        let fetchResponse = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",

            },
            body: JSON.stringify(branchData)
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Success!")
            navigate(-1)
        } else {
            toast.error("Unable to update branch " + response.message)
        }
    }

    const deleteBranch = async () => {
        if (!BRANCH_ID) return alert.error("Invalid Company ID")
        let endpoint = config.endpoint + "/branch/" + BRANCH_ID
        let fetchResponse = await fetch(endpoint, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Success!")
            navigate(-1)
        } else {
            toast.error("Unable to delete branch " + response.message)
        }
    }

    const fetchCompanyData = async () => {
        let endpoint = config.endpoint + "/company/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setCompanyData(data.data)
            if (!branchData?.company_id){
                setBranchData({ ...branchData, company_id: data.data[0].id })
            }
        } else {
            // alert.error(data.message)
        }
    }

    const fetchCompanyServices = async () => {
        let endpoint = config.endpoint + "/service/" + COMPANY_ID
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setCompanyServices(data.data)
        } else {
            // alert.error(data.message)
        }
    }

    const addCommas = (num) => {
        return new Intl.NumberFormat().format(num)
    }

    useEffect(() => {
        fetchCompanyData()
        fetchCompanyServices()
    }, [state, COMPANY_ID])


    return (
        <AdminLayout navSelected={"Branch"}>
            <div className="component">
                <p className='component-title'>{BRANCH_ID === "register" ? "Add Branch" : "Edit Branch"}</p>
            </div>
            <div className="container mt-3">
                <div className="row justifyBetween gap">
                    <div className="card">
                        <p className="card-title mb-10">Branch Details</p>
                        <div className="row gap justifyBetween">
                            <div className="input-container" style={{ width: "70%" }}>
                                <p className="input-label">Name</p>
                                <input className="text-input" type="text" placeholder="Branch Name" value={branchData?.name} onChange={(e) => setBranchData({ ...branchData, name: e.target.value })} />
                            </div>
                            <div className="input-container" style={{ width: "30%" }}>
                                <p className="input-label">Company</p>
                                <select
                                    value={branchData.company_id}
                                    label="Select Company"
                                    className='select-input'
                                    onChange={(e, value) => {
                                        console.log(e.target.value)
                                        setBranchData({ ...branchData, company_id: e.target.value })
                                    }}
                                >
                                    {companyData.map((company, index) => {
                                        return (
                                            <option value={company.id}>{company.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="input-container">
                            <p className="input-label">Remarks</p>
                            <textarea className="textarea-input" type="text" placeholder="Remarks" value={branchData?.remarks} onChange={(e) => setBranchData({ ...branchData, remarks: e.target.value })} />
                        </div>
                    </div>
                </div>
                <div className="row justifyBetween gap" style={{ marginTop: 10 }}>
                    <div className="card">
                        <div className="row justifyBetween alignCenter">
                            <p className="card-title mb-20">Physical Address</p>
                        </div>
                        <div className="input-container">
                            <label htmlFor="">Address Line 1</label>
                            <input type="text" className='text-input' value={branchData?.address_line_1} onChange={(e) => setBranchData({ ...branchData, address_line_1: e.target.value })} />
                        </div>
                        <div className="row gap alignCenter">
                            <div className="input-container" style={{ width: "70%" }}>
                                <label htmlFor="">Address Line 2</label>
                                <input type="text" className='text-input' value={branchData?.address_line_2} onChange={(e) => setBranchData({ ...branchData, address_line_2: e.target.value })} />
                            </div>
                            <div className="input-container" style={{ width: "30%" }}>
                                <label htmlFor="">Postal Code</label>
                                <input type="text" className='text-input' value={branchData?.postal_code} onChange={(e) => setBranchData({ ...branchData, postal_code: e.target.value })} />
                            </div>
                        </div>
                        <div className="row gap alignCenter">
                            <div className="input-container">
                                <label htmlFor="">City</label>
                                <input type="text" className='text-input' value={branchData?.city} onChange={(e) => setBranchData({ ...branchData, city: e.target.value })} />
                            </div>
                            <div className="input-container">
                                <label htmlFor="">State</label>
                                <input type="text" className='text-input' value={branchData?.state} onChange={(e) => setBranchData({ ...branchData, state: e.target.value })} />
                            </div>
                            <div className="input-container">
                                <label htmlFor="">Country</label>
                                <input type="text" className='text-input' value={branchData?.country} onChange={(e) => setBranchData({ ...branchData, country: e.target.value })} />
                            </div>
                            <div className="input-container">
                                <label htmlFor="">Unit Number</label>
                                <input type="text" className='text-input' value={branchData?.unit_number} onChange={(e) => setBranchData({ ...branchData, unit_number: e.target.value })} />
                            </div>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="row justifyBetween mb-20">
                            <p className="card-title">Branch Contact Information</p>
                        </div>
                        <div className="input-container">
                            <label htmlFor="">Email Address</label>
                            <input type="email" className='text-input' value={branchData?.email} onChange={(e) => setBranchData({ ...branchData, email: e.target.value })} />
                        </div>
                        <div className="input-container">
                            <label htmlFor="">Telephone</label>
                            <input type="tel" className='text-input' value={branchData?.telephone} onChange={(e) => setBranchData({ ...branchData, telephone: e.target.value })} />
                        </div>
                        <div className="input-container">
                            <label htmlFor="">Fax</label>
                            <input type="text" className='text-input' value={branchData?.fax} onChange={(e) => setBranchData({ ...branchData, fax: e.target.value })} />
                        </div>
                        <div className="input-container">
                            <label htmlFor="">Website</label>
                            <input type="text" className='text-input' value={branchData?.website} onChange={(e) => setBranchData({ ...branchData, website: e.target.value })} />
                        </div>
                        <div className="input-container">
                            <label htmlFor="">Maps</label>
                            <input type="text" className='text-input' value={branchData?.map_link} onChange={(e) => setBranchData({ ...branchData, map_link: e.target.value })} />
                        </div>
                    </div>
                </div>
                <div className="row justifyBetween gap">
                    <div className="card mt-3">
                        <div className="row justifyBetween mb-20 alignCenter">
                            <p className="card-title">Operating Hours</p>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <button className="backgroundLess" style={{ minWidth: 50, display: 'flex', alignItems: 'center' }}
                                    onClick={() => {
                                        setBranchData({ ...branchData, operating_hours: [...branchData.operating_hours, { day: "", open: "", close: "" }] })
                                    }}
                                >
                                    {<IoAddSharp size={20} />} Add Operating Hours
                                </button> 
                            </div>
                            
                        </div>
                        {branchData?.operating_hours?.map((hours, index) => {
                            return (
                                <div className="alignCenter mb-10" style={{ display: "flex", gap: 10 }}>
                                    <div className="input-container">
                                        <label htmlFor="">Day</label>
                                        <select
                                            value={hours?.day}
                                            label="Select Day"
                                            className='select-input'
                                            onChange={(e, value) => {
                                                let temp = { ...hours }
                                                temp.day = e.target.value
                                                console.log(temp)
                                                let temp2 = [...branchData?.operating_hours]
                                                temp2[index] = temp
                                                setBranchData({ ...branchData, operating_hours: temp2 })
                                            }}
                                        >
                                            <option value="Monday">Monday</option>
                                            <option value="Tuesday">Tuesday</option>
                                            <option value="Wednesday">Wednesday</option>
                                            <option value="Thursday">Thursday</option>
                                            <option value="Friday">Friday</option>
                                            <option value="Saturday">Saturday</option>
                                            <option value="Sunday">Sunday</option>
                                        </select>
                                    </div>
                                    <div className="input-container">
                                        <label htmlFor="">Opening Time</label>
                                        <input type="time" value={hours?.open} className='text-input' onChange={(e) => {
                                            let temp = { ...hours }
                                            temp.open = e.target.value
                                            let temp2 = [...branchData?.operating_hours]
                                            temp2[index] = temp
                                            setBranchData({ ...branchData, operating_hours: temp2 })
                                        }} />
                                    </div>
                                    <div className="input-container">
                                        <label htmlFor="">Closing Time</label>
                                        <input type="time" value={hours?.close} className='text-input' onChange={(e) => {
                                            let temp = { ...hours }
                                            temp.close = e.target.value
                                            let temp2 = [...branchData?.operating_hours]
                                            temp2[index] = temp
                                            setBranchData({ ...branchData, operating_hours: temp2 })
                                        }} />
                                    </div>
                                    <button className="backgroundLess" style={{ minWidth: 50 }}
                                        onClick={() => {
                                            if (branchData?.operating_hours?.length === 1) return
                                            let temp = branchData?.operating_hours
                                            temp.splice(index, 1)
                                            setBranchData({ ...branchData, operating_hours: temp })
                                        }}
                                    >
                                        {<IoClose size={30} />}
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="row justifyBetween gap">
                    <div className="card mt-4">
                        <p className="card-title mb-20">Services</p>
                        {companyServices?.map((service, index) => {
                            return (
                                <div className="row justifyBetween alignCenter">
                                    <div>
                                        <p>{service?.name}</p>
                                        <p style={{ fontSize: 12, color: "gray" }}>${addCommas(service.price)} • {service.duration} min</p>
                                    </div>
                                    <input type="checkbox" checked={branchData?.services?.includes(service?.id)} onChange={(e) => {
                                        let temp = [...branchData?.services]
                                        if (e.target.checked) {
                                            temp.push(service?.id)
                                        } else {
                                            temp.splice(temp.indexOf(service?.id), 1)
                                        }
                                        setBranchData({ ...branchData, services: temp })
                                    }} />
                                </div>
                            )
                        })}
                    </div>
                </div>
                
                <div className="buttonContainer mt-4 mb-4 right">
                    {BRANCH_ID !== "register" && <button className="button" style={{ backgroundColor: "red" }} onClick={() => {
                        deleteBranch()
                    }}>Delete</button>}
                    <button className="button" onClick={() => {
                        if (BRANCH_ID === "register") {
                            // Register
                            registerBranch()
                        } else {
                            // Update
                            updateBranch()
                        }
                    }}>Save</button>
                </div>
            </div>
        </AdminLayout>
    )
}
