import React, {useEffect, useState} from 'react'
import AdminHeader from '../../components/AdminHeader'
import AdminNavbar from '../../components/AdminNavbar'
import { useLocation, useNavigate } from 'react-router'
import { ToastContainer, toast } from 'react-toastify';
import config from '../../app-config'
import { FaSearch } from 'react-icons/fa';
import AdminLayout from '../../layouts/AdminLayout';

export default function AdminStaffPage() {
    const navigate = useNavigate()
    const { state } = useLocation()

    const [staffData, setStaffData] = useState([])
    const [query, setQuery] = useState("")

    useEffect(() => {
        fetchServiceData()
        
    }, [])

    const fetchServiceData = async () => {
        let endpoint = config.endpoint + "/staff/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setStaffData(data.data)
        } else {
            toast.error(data.message)
        }
    }
    return (
        <AdminLayout navSelected={"Service"}>
            <div className="row mt-4 mb-3 component">
                <div className='col-lg-6'>
                    <h2 className="component-title">Staff</h2>
                </div>
                <div className='col-lg-6 d-flex justify-content-end align-items-center'>
                    <button className="btn btn-light justify-content-center" style={{border: "0.5px solid grey", borderRadius: "5px", width:"40%", marginRight:"%" }}
                        onClick={() => navigate("/admin/staff/register")}
                    >Add Staff</button>
                </div>
            </div>

            <div className="container">
                {/* <input type="text" className="text-input mb-10" placeholder="Search staff..." value={query} onChange={(e) => setQuery(e.target.value)} /> */}
                <div className='d-flex justify-content-center'>
                    <p className='align-items-center m-4 fw-bold' style={{fontSize:"1.5em"}}>Search Staff List</p> 
                </div>
                <div className='d-flex justify-content-center'>
                    <div style={{ position: 'relative', width: '90%' }}>
                        <input type="text" className="text-input align-items-center" style={{ lineHeight: "2", width: "100%", paddingLeft: '30px', borderRadius: '20px'  }} placeholder="Search staff..." value={query} onChange={(e) => setQuery(e.target.value)} />
                        <FaSearch style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', opacity:'0.5' }} />
                    </div>
                </div>

                <div className="mt-5">
                    {staffData && staffData.length > 0 ? (
                        staffData.map((staff, index) => {
                            if (JSON.stringify(staff).toLowerCase().includes(query.toLowerCase())) {
                                return (
                                    <div className='card mb-20'>
                                        <div className="row justifyBetween">
                                            <div>
                                                <p style={{ fontSize: 15 }}>{staff.first_name} {staff.last_name}</p>
                                                <p style={{ color: "gray", fontSize: 12 }}>{staff.email}</p>
                                            </div>
                                            <button className="backgroundLess"
                                                onClick={() => navigate("/admin/staff/" + staff.user_id, { state: staff })}
                                            >Edit</button>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    ) : (
                        <div className='d-flex justify-content-center align-items-center' style={{ marginTop:"5%", fontSize:"1.1em"}}>
                            <p>No Staff Added</p>          
                        </div>
                    )}

                </div>
            </div>
        </AdminLayout>
    )
}
