import React, { useEffect, useState } from 'react'
import AdminHeader from '../../components/AdminHeader'
import AdminNavbar from '../../components/AdminNavbar'
import { useLocation, useNavigate } from 'react-router'
import config from '../../app-config'
import { ToastContainer, toast } from 'react-toastify';
import { FaSearch } from 'react-icons/fa';
import AdminLayout from '../../layouts/AdminLayout';

export default function AdminCompanyPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [companyData, setCompanyData] = useState([])
    const [query, setQuery] = useState("")


    useEffect(() => {
        fetchCompanyData()
    }, [])

    const fetchCompanyData = async () => {
        let endpoint = config.endpoint + "/company/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setCompanyData(data.data)
        } else {
            toast.error(data.message)
        }
    }

    return (
        <AdminLayout navSelected={"Company"}>
            <div className="flex-row align-items-center justify-content-between d-flex component">
                <h2 className="component-title" style={{padding:0, margin:0}}>Clients</h2>
                <button className="btn btn-light" style={{ border: "0.5px solid grey", borderRadius: "5px", marginRight: "%" }}
                    onClick={() => navigate("/admin/company/register")}
                >New Client</button>
            </div>

            <div className="container mt-4">
                <div className='d-flex justify-content-center'>
                    <p className='align-items-center m-4 fw-bold' style={{fontSize:"1.5em"}}>Search Client List</p> 
                </div>
                <div className='d-flex justify-content-center'>
                    <div style={{ position: 'relative', width: '90%' }}>
                        <input type="text" className="text-input align-items-center" style={{ lineHeight: "2", width: "100%", paddingLeft: '30px', borderRadius: '20px'  }} placeholder="Search for companies..." value={query} onChange={(e) => setQuery(e.target.value)} />
                        <FaSearch style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', opacity:'0.5' }} />
                    </div>
                </div>

                <div className='mt-5'>
                    {companyData && companyData.length > 0 ? (
                        companyData.map((company, index) => {
                            if (JSON.stringify(company).toLowerCase().includes(query.toLowerCase())){
                                return (
                                    <div className='card mb-20'>
                                        <div className="row justifyBetween">
                                            <div>
                                                <p style={{ fontSize: 15 }}>{company.name}</p>
                                                <p style={{ color: "gray", fontSize: 12 }}>{company.code}</p>
                                            </div>
                                            <button className="backgroundLess"
                                                onClick={() => navigate("/admin/company/" + company.id, { state: company  })}
                                            >Edit</button>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    ) : (
                        <div className='d-flex justify-content-center align-items-center' style={{ marginTop:"5%", fontSize:"1.1em"}}>
                            <p>No Clients Added</p>          
                        </div>
                    )}
                    
                </div>
            </div>
        </AdminLayout>
    )
}
