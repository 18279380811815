import React, {useEffect, useState} from 'react'
import AdminHeader from '../../components/AdminHeader'
import AdminNavbar from '../../components/AdminNavbar'
import { useLocation, useNavigate } from 'react-router'
import { ToastContainer, toast } from 'react-toastify';
import config from '../../app-config'
import AdminLayout from '../../layouts/AdminLayout';



export default function AdminIndexPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    return (
        <AdminLayout navSelected={"Dashboard"} style={{ marginTop: "3%" }}>
            <div className="container p-2">
                {Array(1000).fill(0).map((_, i) => (
                    <p>{i}</p>
                ))}
            </div>
        </AdminLayout>
    )
}
