import React, { useEffect, useState } from 'react'
import AdminHeader from '../../components/AdminHeader'
import AdminNavbar from '../../components/AdminNavbar'
import { useLocation, useNavigate } from 'react-router'
import { ToastContainer, toast } from 'react-toastify';
import config from '../../app-config'
import MerchantNavbar from '../../components/MerchantNavbar'
import MerchantHeader from '../../components/MerchantHeader'
import { FaSearch } from 'react-icons/fa';
import MerchantLayout from '../../layouts/MerchantLayout';


export default function MerchantStaffPage() {
    const navigate = useNavigate()
    const { state } = useLocation()

    const [staffData, setStaffData] = useState([])
    const [query, setQuery] = useState("")

    useEffect(() => {
        fetchServiceData()

    }, [])

    const fetchServiceData = async () => {
        let endpoint = config.endpoint + "/staff/merchant/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setStaffData(data.data)
        } else {
            toast.error(data.message)
        }
    }
    return (
        <MerchantLayout navSelected={"Staff"}>
            <div className="d-flex justify-content-between flex-row component">
                <h2 className="component-title">Staff</h2>
                <button className="btn btn-light" style={{ border: "0.5px solid grey", borderRadius: "5px", marginRight: "%" }}
                    onClick={() => navigate("/merchant/staff/register")}
                >Add Staff</button>
            </div>


            <div className="container mt-4">
                <div className='d-flex justify-content-center'>
                    <p className='align-items-center m-4 fw-bold' style={{ fontSize: "1.5em" }}>Search Staff List</p>
                </div>
                <div className='d-flex justify-content-center'>
                    <div style={{ position: 'relative', width: '90%' }}>
                        <input type="text" className="text-input align-items-center" style={{ lineHeight: "2", width: "100%", paddingLeft: '30px', borderRadius: '20px' }} placeholder="Search staff..." value={query} onChange={(e) => setQuery(e.target.value)} />
                        <FaSearch style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', opacity: '0.5' }} />
                    </div>
                </div>

                <div className='mt-5'>
                    {staffData && staffData.length > 0 ? (
                        staffData.map((staff, index) => {
                            if (JSON.stringify(staff).toLowerCase().includes(query.toLowerCase())) {
                                return (
                                    <div className="card mt-3">
                                        <div className="card-body d-flex justify-content-between align-items-center">
                                            <div>
                                                <p style={{ fontSize: 16 }}>{staff.first_name} {staff.last_name}</p>
                                                <p style={{ color: "gray", fontSize: 12 }}>{staff.email}</p>
                                            </div>
                                           
                                            <button className="btn btn-light"
                                                onClick={() => navigate("/merchant/staff/" + staff.user_id, { state: staff })}
                                            >Edit</button>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    ) : (
                        <div className='d-flex justify-content-center align-items-center' style={{ marginTop: "5%", fontSize: "1.1em" }}>
                            <p>No Staff Added</p>
                        </div>
                    )}

                </div>
            </div>
        </MerchantLayout>
    )
}
