import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import MerchantHeader from '../../components/MerchantHeader'
import MerchantNavbar from '../../components/MerchantNavbar'
import { ToastContainer, toast } from 'react-toastify';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Paper from '@mui/material/Paper';
import { ViewState, EditingState, IntegratedEditing } from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    DayView,
    Toolbar,
    DateNavigator,
    Appointments,
    AppointmentTooltip,
    TodayButton,
} from '@devexpress/dx-react-scheduler-material-ui';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { IoAdd, IoClose } from 'react-icons/io5'
import config from '../../app-config'
import Select from 'react-select'
import MerchantLayout from '../../layouts/MerchantLayout';

const currentDate = '2018-11-01'

export default function MerchantBookingsPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [heightOfArea, setHeightOfArea] = useState(window.innerHeight - document.getElementById("app-header")?.clientHeight - document.querySelector(".component")?.clientHeight)
    const [selectedDate, setSelectedDate] = useState(tidyDate(new Date()))

    const [selectedSchedule, setSelectedSchedule] = useState()
    const [companyServices, setCompanyServices] = useState([])
    const [customerData, setCustomerData] = useState([])
    const [staffData, setStaffData] = useState([])
    const [branchData, setBranchData] = useState([])
    const [showText, setShowText] = useState(false)

    const [schedulerData, setSchedulerData] = useState([
        { startDate: '2023-05-10T12:00', endDate: '2023-05-10T13:30', title: 'Go to a gym', payload: "hi" },
        { startDate: '2023-05-10T12:00', endDate: '2023-05-10T13:30', title: 'Go to a gym', payload: "hi" },
        { startDate: '2023-05-10T09:45', endDate: '2023-05-10T11:00', title: 'Meeting', payload: "hi" },
        { startDate: '2023-05-10T12:00', endDate: '2023-05-10T13:30', title: 'Go to a gym', payload: "hi" },
    ])

    let modal = {
        startDate: `${tidyDate(new Date())}T12:00`,
        endDate: `${tidyDate(new Date())}T13:30`,
        title: "",
        service_id: "",
        duration: "",
        price: "",
        special_request: "",
        booking_status: "confirmed", //confirmed, pending, cancelled, completed
        user_id: "",
        staff_id: "",
        company_id: "",
        branch_id: "",
        branch_info: {},
        customer_info: {},
        staff_info: {},
        service_info: {},
    }

    function tidyDate(date) {
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        if (month < 10) {
            month = `0${month}`
        }
        if (day < 10) {
            day = `0${day}`
        }

        return `${year}-${month}-${day}`
    }

    function uiDate(date) { //day, Date Month Year
        let date_day = date.split("-")[2]
        let date_month = date.split("-")[1]
        let date_year = date.split("-")[0]

        let dateObj = new Date(date_year, date_month - 1, date_day)

        let year = dateObj.getFullYear()
        let month = dateObj.getMonth() + 1
        let day = dateObj.getDate()
        let dayOfWeek = dateObj.getDay()
        let monthName = dateObj.toLocaleString('default', { month: 'long' })
        let dayName = dateObj.toLocaleString('default', { weekday: 'long' })
        return `${dayName}, ${day} ${monthName} ${year}`
    }


    const fetchCompanyServices = async () => {
        let endpoint = config.endpoint + "/service/" + state?.company_id
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setCompanyServices(data.data)
        } else {
            toast.error(data.message)
        }
    }



    const fetchCustomerData = async () => {
        let endpoint = config.endpoint + "/customer/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (response.status === 200) {
            setCustomerData(data.data)
        } else {
            toast.error("Unable to fetch customer data " + data.message)
        }
    }

    const fetchStaffData = async () => {
        let endpoint = config.endpoint + "/staff/merchant/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (response.status === 200) {
            setStaffData(data.data)
        } else {
            toast.error("Unable to fetch customer data " + data.message)
        }
    }

    useLayoutEffect(() => {
        fetchCustomerData()
        fetchStaffData()
        getSchedule()
        fetchCompanyServices()
        get_company_branch()
        setHeightOfArea(window.innerHeight - document.getElementById("app-header")?.clientHeight - document.querySelector(".component")?.clientHeight)
    }, [heightOfArea])


    useEffect(() => {
        if (selectedSchedule?.startDate) {
            let temp = { ...selectedSchedule }
            temp.title = `[${temp?.staff_info?.first_name ? temp?.staff_info?.first_name : ""}]: ${temp?.service_info?.name ? temp?.service_info?.name : ""} - ${temp?.customer_info?.first_name ? temp?.customer_info?.first_name : ""}`
            if (temp?.startDate) {
                setSelectedSchedule(temp)
            }
        }
        // get_more_details(selectedSchedule)
    }, [selectedSchedule])

    const MyAppointment = ({ children, style, ...restProps }) => {

        return (
            <Appointments.Appointment
                {...restProps}
                style={{
                    ...style,
                    backgroundColor: "#12344d",
                    borderRadius: "10px",
                    opacity: selectedSchedule?.id === restProps?.data?.id ? "1" : "0.5",
                }}
                onClick={() => {
                    setSelectedSchedule(restProps.data)
                    get_more_details(restProps.data)
                }}
            >
                {children}
            </Appointments.Appointment>
        );
    }

    const submitChanges = async () => {
        let endpoint = config.endpoint + "/bookings/"
        let response = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(selectedSchedule)
        })
        let data = await response.json()
        if (response.status === 200) {
            toast.success("Successfully updated schedule")
            setSelectedSchedule()
            getSchedule()
        } else {
            toast.error("Unable to update schedule " + data.message)
        }
    }

    const createSchedule = async () => {
        let endpoint = config.endpoint + "/bookings/"
        let response = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(selectedSchedule)
        })
        let data = await response.json()
        if (response.status === 200) {
            toast.success("Successfully created schedule")
            setSelectedSchedule()
            getSchedule()
        } else {
            toast.error("Unable to create schedule " + data.message)
        }
    }

    const deleteSchedule = async () => {
        let endpoint = config.endpoint + "/bookings/" + selectedSchedule?.id
        let response = await fetch(endpoint, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let data = await response.json()
        if (response.status === 200) {
            toast.success("Successfully deleted schedule")
            setSelectedSchedule()
        } else {
            toast.error("Unable to delete schedule " + data.message)
        }
    }

    const get_more_details = async (data) => {
        // if (!data?.id) return
        // if (!data?.service_id) return
        // if (!data?.user_id) return
        // if (!data?.staff_id) return
        // if (data?.staff_info?.id) return
        // if (data?.customer_info?.id) return
        // if (data?.service_info?.id) return
        let endpoint = config.endpoint + "/bookings/details/" + data?.id
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            let staff_data = response?.data?.staff
            let user_data = response?.data?.user
            let service_data = response?.data?.service
            let branch_data = response?.data?.branch

            let temp = { ...data }
            temp.customer_info = user_data
            temp.service_info = service_data
            temp.staff_info = staff_data
            temp.branch_info = branch_data
            console.log({ selectedSchedule })
            if (temp?.startDate) {
                setSelectedSchedule(temp)
            }
        } else {
            toast.error("Unable to get schedule " + response.message)
        }
    }

    const getSchedule = async () => {
        let endpoint = config.endpoint + "/bookings/merchant/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let data = await response.json()
        if (response.status === 200) {
            // setSelectedSchedule()
            setSchedulerData(data.data)
        } else {
            toast.error("Unable to get schedule " + data.message)
        }
    }

    const capitaliseFirst = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const get_company_branch = async () => {
        let endpoint = config.endpoint + "/branch/merchant/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let data = await response.json()
        if (response.status === 200) {
            // setSelectedSchedule()
            setBranchData(data.data)
        } else {
            toast.error("Unable to get schedule " + data.message)
        }
    }



    return (
        <>
            {!selectedSchedule && <button className="button gap alignCenter" style={{ position: "fixed", bottom: 30, right: 30, zIndex: 6, display: "flex", padding: "20px 15px", borderRadius: "10px" }}
                onClick={() => {
                    setSelectedSchedule(modal)
                }}
            >
                <IoAdd />
                <span>New Booking</span>
            </button>}
            <MerchantLayout navSelected={"Bookings"}>
                <div className="component px-2 py-2" style={{ margin: 0, padding: 0, display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        <div className="d-flex gap-5" style={{ marginLeft: "1%" }}>
                            <button className="backgroundLess"
                                onClick={() => {
                                    let date = selectedDate
                                    let date_day = date.split("-")[2]
                                    let date_month = date.split("-")[1]
                                    let date_year = date.split("-")[0]
                                    let dateObj = new Date(date_year, date_month - 1, date_day)
                                    dateObj.setDate(dateObj.getDate() - 1)
                                    setSelectedDate(tidyDate(dateObj))
                                }}
                            >
                                {<BsChevronLeft size={20} />}
                            </button>
                            <p style={{ marginLeft: "2%", whiteSpace: "nowrap", padding: 0, margin: 0 }}>{uiDate(selectedDate)}</p>
                            <button className="backgroundLess"
                                onClick={() => {
                                    let date = selectedDate
                                    let date_day = date.split("-")[2]
                                    let date_month = date.split("-")[1]
                                    let date_year = date.split("-")[0]
                                    let dateObj = new Date(date_year, date_month - 1, date_day)
                                    dateObj.setDate(dateObj.getDate() + 1)
                                    setSelectedDate(tidyDate(dateObj))
                                }}
                            >
                                {<BsChevronRight size={20} />}
                            </button>
                        </div>
                    </div>
                    <button className="btn btn-light" style={{ border: "0.5px solid grey", borderRadius: "5px" }}
                        onClick={() => {
                            setSelectedDate(tidyDate(new Date()))
                        }}
                    >Today</button>

                </div>
                <div className='' style={{ alignItems: "flex-start", display: "flex", flexDirection: "row" }}>
                    <Paper>
                        <Scheduler
                            data={schedulerData}
                            height={heightOfArea}
                        >
                            <ViewState currentDate={selectedDate ? selectedDate : currentDate} />
                            <DayView startDayHour={0} endDayHour={24} cellDuration={60} />
                            <Appointments appointmentComponent={MyAppointment} />
                        </Scheduler>
                    </Paper>
                    {selectedSchedule && <div className="container" style={{ minWidth: "500px", height: heightOfArea, overflowY: "scroll" }}>
                        <div className="row justifyBetween alignCenter mb-20">
                            <p className="component-title">{selectedSchedule.title}</p>
                            <button className="backgroundLess"
                                onClick={() => {
                                    setSelectedSchedule()
                                }}
                            >
                                {<IoClose size={20} />}
                            </button>
                        </div>
                        {/* <p>{JSON.stringify(selectedSchedule)}</p> */}
                        <div className="card">
                            <p className="card-title mb-20">Appointment Details</p>
                            <div className="input-container">
                                <p className="input-label">Date of Appointment</p>
                                <input className="text-input" type="date" value={selectedSchedule?.startDate?.split("T")[0]}
                                    onChange={(e) => {
                                        let date = { ...selectedSchedule }
                                        date.startDate = e.target.value + "T" + date?.startDate?.split("T")[1]
                                        date.endDate = e.target.value + "T" + date?.endDate?.split("T")[1]
                                        setSelectedSchedule(date)
                                    }}
                                />
                            </div>
                            <div className="row gap justifyBetween alignCenter">
                                <div className="input-container">
                                    <p className="input-label">Start Time</p>
                                    <input className="text-input" type="time" value={selectedSchedule?.startDate?.split("T")[1]}
                                        onChange={(e) => {
                                            let date = { ...selectedSchedule }
                                            date.startDate = date?.startDate?.split("T")[0] + "T" + e.target.value
                                            setSelectedSchedule(date)
                                        }}
                                    />
                                </div>
                                <div className="input-container">
                                    <p className="input-label">End Time</p>
                                    <input className="text-input" type="time" value={selectedSchedule?.endDate?.split("T")[1]}
                                        onChange={(e) => {
                                            let date = { ...selectedSchedule }
                                            date.endDate = date?.endDate?.split("T")[0] + "T" + e.target.value
                                            setSelectedSchedule(date)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="input-container">
                                <label htmlFor="" className="input-label">Appointment Status</label>
                                <Select
                                    value={{ value: selectedSchedule?.booking_status, label: capitaliseFirst(selectedSchedule?.booking_status) }}
                                    onChange={(e) => {
                                        let schedule = { ...selectedSchedule }
                                        schedule.booking_status = e.value
                                        setSelectedSchedule(schedule)
                                    }}
                                    options={[
                                        { value: "pending", label: "Pending" },
                                        { value: "confirmed", label: "Confirmed" },
                                        { value: "completed", label: "Completed" },
                                        { value: "cancelled", label: "Cancelled" },
                                        { value: "noshow", label: "No Show" }
                                    ]}
                                />
                            </div>

                            <div className="input-container">
                                <label htmlFor="" className="input-label">Branch</label>
                                {/* <p>{selectedSchedule?.service_id}</p> */}
                                <Select

                                    value={{ value: selectedSchedule?.branch_id, label: selectedSchedule?.branch_info?.name }}
                                    onChange={(e) => {
                                        let schedule = { ...selectedSchedule }
                                        schedule.branch_id = e.value
                                        schedule.branch_info = e.payload
                                        setSelectedSchedule(schedule)
                                    }}
                                    options={branchData.map((branch) => {
                                        return (
                                            { value: branch.id, label: branch.name, payload: branch }
                                        )
                                    })}
                                />
                            </div>

                            <div className="input-container">
                                <label htmlFor="" className="input-label">Notes</label>
                                <textarea className="textarea-input" type="text" value={selectedSchedule?.special_request} onChange={(e) => {
                                    let schedule = { ...selectedSchedule }
                                    schedule.special_request = e.target.value
                                    setSelectedSchedule(schedule)
                                }} />
                            </div>
                        </div>

                        <div className="card mt-10">
                            <p className="card-title mb-20">Service</p>
                            <div className="input-container">
                                <label htmlFor="" className="input-label">Selected Service</label>
                                {/* <p>{selectedSchedule?.service_id}</p> */}
                                <Select

                                    value={{ value: selectedSchedule?.service_id, label: selectedSchedule?.service_info?.name }}
                                    onChange={(e) => {
                                        let schedule = { ...selectedSchedule }
                                        schedule.service_id = e.payload.id
                                        schedule.duration = e.payload.duration
                                        schedule.price = e.payload.price
                                        schedule.service_info = e.payload
                                        console.log({ here: schedule })
                                        setSelectedSchedule(schedule)
                                    }}
                                    options={companyServices.map((service) => {
                                        return (
                                            { value: service.id, label: service.name, payload: service }
                                        )
                                    })}
                                />
                            </div>
                            <div className="input-container">
                                <label htmlFor="" className="input-label">Staff</label>
                                <Select
                                    value={{ value: selectedSchedule?.staff_id, label: `${selectedSchedule?.staff_info?.first_name} ${selectedSchedule?.staff_info?.last_name}` }}
                                    onChange={(e) => {
                                        let schedule = { ...selectedSchedule }
                                        schedule.staff_id = e.payload.user_id
                                        schedule.staff_info = e.payload
                                        setSelectedSchedule(schedule)
                                    }}
                                    options={staffData.map((staff) => {
                                        return (
                                            { value: staff.user_id, label: `${staff.first_name} ${staff.last_name}`, payload: staff }
                                        )
                                    })}
                                />
                            </div>
                            <div className="input-container">
                                <label htmlFor="" className="input-label">Duration</label>
                                <input className="text-input" type="number" value={selectedSchedule.duration}
                                    onChange={(e) => {
                                        let date = { ...selectedSchedule }
                                        date.duration = e.target.value
                                        setSelectedSchedule(date)
                                    }}
                                />
                            </div>
                            <div className="input-container">
                                <label htmlFor="" className="input-label">Price</label>
                                <input className="text-input" type="number" value={selectedSchedule.price}
                                    onChange={(e) => {
                                        let date = { ...selectedSchedule }
                                        date.price = e.target.value
                                        setSelectedSchedule(date)
                                    }}
                                />
                            </div>
                        </div>

                        <div className="card mt-10">
                            <p className="card-title mb-20">Client Details</p>
                            <div className="input-container">
                                <label htmlFor="" className="input-label">Client</label>
                                <Select
                                    value={{ value: selectedSchedule?.user_id, label: `${selectedSchedule?.customer_info?.first_name} ${selectedSchedule?.customer_info?.last_name}` }}
                                    onChange={(e) => {
                                        let schedule = { ...selectedSchedule }
                                        schedule.user_id = e.payload.id
                                        schedule.customer_info = e.payload
                                        setSelectedSchedule(schedule)
                                    }}
                                    options={customerData.map((customer) => {
                                        return (
                                            { value: customer.id, label: `${customer.first_name} ${customer.last_name}`, payload: customer }
                                        )
                                    })}
                                />
                            </div>
                            <div className="input-container">
                                <label htmlFor="" className="input-label">Phone Number</label>
                                <input className="text-input" type="text" value={selectedSchedule?.customer_info?.contact_number} disabled />
                            </div>
                            <div className="input-container">
                                <label htmlFor="" className="input-label">Email</label>
                                <input className="text-input" type="text" value={selectedSchedule?.customer_info?.email} disabled />
                            </div>
                        </div>

                        <div className="buttonContainer right mt-10">
                            {selectedSchedule?.id && <button className="button" onClick={() => {
                                deleteSchedule()
                            }}>Delete</button>}
                            <button className="button"
                                onClick={() => {
                                    if (selectedSchedule?.id) {
                                        submitChanges()
                                    } else {
                                        createSchedule()
                                    }
                                }}
                            >Save</button>
                        </div>

                    </div>}
                </div>
            </MerchantLayout>
        </>
    )
}

