import React, { useEffect, useState } from 'react'
import AdminHeader from '../../components/AdminHeader'
import AdminNavbar from '../../components/AdminNavbar'
import { useLocation, useNavigate, useParams } from 'react-router'
import { IoAddSharp, IoClose } from 'react-icons/io5'
import config from '../../app-config'
import { ToastContainer, toast } from 'react-toastify';
import MerchantHeader from '../../components/MerchantHeader'
import MerchantNavbar from '../../components/MerchantNavbar'
import MerchantLayout from '../../layouts/MerchantLayout'


export default function MerchantStaffFormPage() {
    const navigate = useNavigate()
    const { state } = useLocation()

    const STAFF_ID = useParams().id || state?.id

    const [companyData, setCompanyData] = useState([])
    const [branchData, setBranchData] = useState([])
    const [staffData, setStaffData] = useState(state ? state : {
        id: "",
        first_name: "",
        last_name: "",
        account_type: "merchant-staff",
        email: "",
        contact_number: "",
        username: "",
        account_remarks: "",
        password: "",
        company_id: "",
        branch_access: [],
        is_deleted: 0,
        staff_id: STAFF_ID,
    })

    const registerStaff = async () => {
        let endpoint = config.endpoint + "/staff/"
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",

            },
            body: JSON.stringify(staffData)
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Success!")
            navigate("/merchant/staff")
        } else {
            toast.error("Unable to register staff " + response.message)
        }
    }

    const updateStaff = async () => {
        let endpoint = config.endpoint + "/staff/"
        let fetchResponse = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",

            },
            body: JSON.stringify(staffData)
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Success!")
            navigate("/merchant/staff")

        } else {
            toast.error("Unable to update staff " + response.message)
        }
    }

    const deleteStaff = async () => {
        if (!STAFF_ID) return alert.error("Invalid service ID")
        let endpoint = config.endpoint + "/staff/" + STAFF_ID
        let fetchResponse = await fetch(endpoint, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Success!")
            navigate("/merchant/staff")

        } else {
            toast.error("Unable to delete staff " + response.message)
        }
    }

    const fetchCompanyData = async () => {
        let endpoint = config.endpoint + "/company/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setCompanyData(data.data)
            setStaffData({ ...staffData, company_id: data.data[0].id })
        } else {
            toast.error(data.message)
        }
    }
    const fetchBranchData = async () => {
        let endpoint = config.endpoint + "/branch/merchant/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setBranchData(data.data)
        } else {
            toast.error(data.message)
        }
    }

    const changePassword = async () => {
        let endpoint = config.endpoint + "/staff/password"
        console.log(staffData.password)
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                staff_id: STAFF_ID,
                user_id: staffData.user_id,
                password: staffData.password,
            })
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Success, password set!")
        } else {
            toast.error("Unable to update staff " + response.message)
        }
    }

    useEffect(() => {
        fetchBranchData()
        // fetchCompanyData()
    }, [])

    return (
        <MerchantLayout navSelected={"Staff"}>
            <div className="component">
                <p className='component-title'>{STAFF_ID === "register" ? "Add Staff" : "Edit Staff"}</p>
            </div>
            <div className="container mt-20">
                <div className="row justifyBetween gap">
                    <div className="card">
                        <p className="card-title mb-20">Staff Details</p>
                        <div className="row gap">
                            <div className="input-container" style={{ width: "40%" }}>
                                <p className="input-label">First Name</p>
                                <input className="text-input" type="text" placeholder="e.g. John" value={staffData?.first_name} onChange={(e) => setStaffData({ ...staffData, first_name: e.target.value })} />
                            </div>
                            <div className="input-container" style={{ width: "40%" }}>
                                <p className="input-label">Last Name</p>
                                <input className="text-input" type="text" placeholder="Last Name" value={staffData?.last_name} onChange={(e) => setStaffData({ ...staffData, last_name: e.target.value })} />
                            </div>
                        </div>
                        <div className="input-container">
                            <p className="input-label">Account Remarks</p>
                            <textarea className="textarea-input" type="text" placeholder="Remarks" value={staffData?.account_remarks} onChange={(e) => setStaffData({ ...staffData, account_remarks: e.target.value })} />
                        </div>
                    </div>
                </div>

                <div className="row justifyBetween gap" style={{ marginTop: 10 }}>
                    <div className="card">
                        <p className="card-title mb-20">Access Control</p>
                        {STAFF_ID === "register" ?
                            <div className="input-container">
                                <label htmlFor="">Password</label>
                                <input className="text-input" type="text" placeholder="Password" value={staffData?.password} disabled={STAFF_ID !== "register"} onChange={(e) => setStaffData({ ...staffData, password: e.target.value })} />
                            </div>
                            :
                            <div className="row gap justifyBetween">
                                <div className="input-container">
                                    <label htmlFor="">Password</label>
                                    <input className="text-input" type="text" placeholder="Password" value={staffData?.password} onChange={(e) => setStaffData({ ...staffData, password: e.target.value })} />
                                </div>
                                <button className="backgroundLess" style={{ fontSize: 12, minWidth: 100, padding: 0, height: "auto" }}
                                    onClick={() => {
                                        changePassword()
                                    }}
                                >Set Password</button>
                            </div>

                        }
                        <div className="input-container">
                            <label htmlFor="">Account Type</label>
                            <select className="select-input" value={staffData?.account_type} onChange={(e) => setStaffData({ ...staffData, account_type: e.target.value })}>
                                <option value="merchant-manager">Manager</option>
                                <option value="merchant-staff">Staff</option>
                            </select>
                        </div>
                        <div className="input-container">
                            <label htmlFor="">Access Control</label>
                            {/* <p>{JSON.stringify(branchData?.branch_access)}</p> */}
                            <div className="row alignCenter gap">
                                <div className="input-container" style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "auto" }}>
                                    <input type="checkbox" className='checkbox-input' checked={staffData?.branch_access?.includes("all")} onChange={(e) => {
                                        if (e.target.checked) {
                                            setStaffData({ ...staffData, branch_access: ["all"] })
                                        } else {
                                            setStaffData({ ...staffData, branch_access: [] })
                                        }
                                    }} />
                                    <p className="input-label mt-3">All</p>
                                </div>
                                {branchData && branchData.map((branch, index) => {
                                    return (
                                        <div className="input-container row" style={{ flexDirection: "row", width: "auto" }}>
                                            <input type="checkbox" className='checkbox-input' checked={staffData?.branch_access?.includes(branch.id) || staffData?.branch_access?.includes("all")} onChange={(e) => {
                                                if (e.target.checked) {
                                                    setStaffData({ ...staffData, branch_access: [...staffData.branch_access, branch.id] })
                                                } else {
                                                    let temp = staffData.branch_access
                                                    temp.splice(temp.indexOf(branch.id), 1)
                                                    setStaffData({ ...staffData, branch_access: temp })
                                                }
                                            }} />
                                            <p className="input-label">{branch.name}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                    <div className="card">
                        <p className="card-title mb-4">Contact Information</p>
                        <div className="input-container">
                            <label htmlFor="">Username</label>
                            <input className="text-input" type="text" placeholder="Username" value={staffData?.username} disabled={STAFF_ID !== "register"} onChange={(e) => setStaffData({ ...staffData, username: e.target.value.toLowerCase().replaceAll(" ", "") })} />
                        </div>
                        <div className="input-container">
                            <label htmlFor="">Email Address</label>
                            <input className="text-input" type="email" placeholder="e.g. johntan@abc.com" value={staffData?.email} disabled={STAFF_ID !== "register"} onChange={(e) => setStaffData({ ...staffData, email: e.target.value })} />
                        </div>
                        <div className="input-container">
                            <label htmlFor="">Contact Number</label>
                            <input className="text-input" type="tel" placeholder="8 digit number" value={staffData?.contact_number} onChange={(e) => setStaffData({ ...staffData, contact_number: e.target.value })} />
                        </div>
                    </div>
                </div>
                <div className="buttonContainer mt-4 mb-4 right">
                    {STAFF_ID !== "register" && <button className="button" style={{ backgroundColor: "red" }} onClick={() => {
                        deleteStaff()
                    }}>Delete</button>}
                    <button className="button" onClick={() => {
                        if (STAFF_ID === "register") {
                            // Register
                            registerStaff()
                        } else {
                            // Update
                            updateStaff()
                        }
                    }}>Save</button>
                </div>
            </div>
        </MerchantLayout>
    )
}
