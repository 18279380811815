import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import MerchantHeader from '../../components/MerchantHeader'
import MerchantNavbar from '../../components/MerchantNavbar'
import { ToastContainer, toast } from 'react-toastify';
import EmailEditor from 'react-email-editor';
import config from '../../app-config'
import Select from 'react-select'
import MerchantLayout from '../../layouts/MerchantLayout';


export default function MerchantAnnouncementFormPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const ANNOUNCEMENT_ID = useParams().id || state?.id
    const [pageState, setPageState] = useState("email") // email or sms
    const [emailData, setEmailData] = useState("")
    const [heightAvail, setHeightAvail] = useState(window.innerHeight - document.getElementById("header")?.clientHeight - document.getElementById("component")?.clientHeight)

    const [announcementData, setAnnouncementData] = useState(state ? state : {
        id: "",
        internal_name: "",
        type: "email", // email, sms
        send_date: "",
        send_time: "",
        audience: [], // all, customer, merchant
        status: "draft", // draft, scheduled, published
        subject: "",
        email_data: "",
    })

    const [programData, setProgramData] = useState({
        starting_points: 0,
        points_per_visit: 0,
        points_per_dollar: 10,
        membership_duration: 365,
        membership_expiration: 0, //days since membership duration
        membership_tiers: [],// {tier_name: "Gold", tier_points: 1000,}
        is_forever: 0,
        renewable_points: 0,
    })


    useEffect(() => {
        fetchConfig()
    }, [])

    const fetchConfig = async () => {
        let endpoint = config.endpoint + "/loyalty/config"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (response.status === 200) {
            setProgramData(data.data)
        } else {
            toast.error("Failed to fetch loyalty program configuration")
        }
    }

    useLayoutEffect(() => {
        function updateSize() {
            setHeightAvail(window.innerHeight - document.getElementById("app-header")?.clientHeight - document.querySelector(".component")?.clientHeight)
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [])

    const emailEditorRef = useRef(null);

    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
            console.log({ data });
            // return
            if (announcementData?.id) {
                updateBroadcast(data)
                console.log('update');
            } else {
                createBroadcast(data)
            }
        });
    };

    const onReady = () => {
        // editor is ready
        // you can load your template here;
        // const templateJson = {};
        if (emailData) {
            emailEditorRef.current.editor.loadDesign({
                ...emailData
            });
        }


    };

    const fetchResource = async () => {
        if (!ANNOUNCEMENT_ID || ANNOUNCEMENT_ID === "create") return
        let endpoint = config.endpoint + "/broadcast/resource" + (ANNOUNCEMENT_ID ? "/" + ANNOUNCEMENT_ID : "")
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            console.log(JSON.parse(response.data).design)

            setEmailData(JSON.parse(response.data).design)

        } else {
            toast.error("Unable to fetch resource")
        }
    }


    useEffect(() => {
        fetchResource()
    }, [])

    const capitaliseFirst = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const updateBroadcast = async (html) => {
        console.log({
            b_data: { ...announcementData },
            email_data: JSON.stringify(html)
        })
        // return
        let endpoint = config.endpoint + "/broadcast/"
        let fetchResponse = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                b_data: { ...announcementData },
                email_data: JSON.stringify(html)
            })
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            navigate("/merchant/announcements")
            toast.success("Broadcast updated")
        } else {
            toast.error("Failed to update broadcast")
        }
    }

    const createBroadcast = async (html) => {
        // return
        let endpoint = config.endpoint + "/broadcast/"
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                b_data: announcementData,
                email_data: JSON.stringify(html)
            })
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            navigate("/merchant/announcements")
            toast.success("Broadcast created")
        } else {
            toast.error("Failed to create broadcast")
        }
    }

    const deleteBroadcast = async () => {
        if (!announcementData?.id) return
        let endpoint = config.endpoint + "/broadcast/" + announcementData?.id
        let fetchResponse = await fetch(endpoint, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            navigate("/merchant/announcements")
            toast.success("Broadcast deleted")
        } else {
            toast.error("Failed to delete broadcast")
        }
    }



    return (
        <MerchantLayout navSelected={"Broadcast"}>
            <div className="component" id='component'>
                <p className="component-title">{ANNOUNCEMENT_ID === "create" ? "Create Broadcast" : "Edit Broadcast"}</p>
            </div>
            <div className='' style={{ flexDirection: "row", alignItems: "flex-start", display: "flex", }} >
                <div className='' style={{ maxHeight: heightAvail, overflowY: "scroll", width: "500px", padding: 10, paddingInline: 20 }}>
                    <div className="card">
                        <p className="card-title mb-20">Broadcast Settings</p>
                        <div className="input-container">
                            <p className="input-label">Internal Name</p>
                            <input className="text-input" type="text" placeholder="Enter internal name" value={announcementData.internal_name} onChange={(e) => {
                                setAnnouncementData({ ...announcementData, internal_name: e.target.value })
                            }} />
                        </div>
                        {announcementData?.type === "email" && <div className="input-container">
                            <p className="input-label">Email Subject</p>
                            <input className="text-input" type="text" placeholder="Enter email subject" value={announcementData.subject} onChange={(e) => {
                                setAnnouncementData({ ...announcementData, subject: e.target.value })
                            }} />
                        </div>}

                        <div className="input-container">
                            <p className="input-label">Send Date</p>
                            <input className="text-input" type="date" placeholder="Enter send date" value={announcementData.send_date} onChange={(e) => {
                                setAnnouncementData({ ...announcementData, send_date: e.target.value })
                            }} />
                        </div>
                        <div className="input-container">
                            <p className="input-label">Send Time</p>
                            <input className="text-input" type="time" placeholder="Enter send time" value={announcementData.send_time} onChange={(e) => {
                                setAnnouncementData({ ...announcementData, send_time: e.target.value })
                            }} />
                        </div>
                        <div className="input-container">
                            <label htmlFor="" className="input-label">Status</label>
                            <Select
                                value={{ value: announcementData.status, label: capitaliseFirst(announcementData.status) }}
                                onChange={(e) => {
                                    setAnnouncementData({ ...announcementData, status: e.value })
                                }}
                                options={[
                                    { value: "draft", label: "Draft" },
                                    { value: "scheduled", label: "Scheduled" },
                                    { value: "published", label: "Published" },
                                ]}
                            // isDisabled={announcementData.status === "published"}
                            />
                        </div>

                    </div>

                    <div className="card mt-4">
                        <div className="card-body">
                            <p className="card-title mb-20">Audience</p>
                            <div className="input-container">
                                {/* <p className="input-label" style={{ fontWeight: "bold" }}>Audience</p> */}
                                <div className="column">
                                    <div className="d-flex flex-row gap alignCenter mt-3">
                                        <input type="checkbox" checked={announcementData?.audience?.includes("all")} onChange={(e) => {
                                            if (e.target.checked) {
                                                setAnnouncementData({ ...announcementData, audience: ["all"] })
                                            } else {
                                                setAnnouncementData({ ...announcementData, audience: [] })
                                            }
                                        }} />
                                        <p className="input-label" style={{ fontWeight: "bold", marginBottom: 0 }}>All</p>
                                    </div>
                                    <div className="d-flex flex-row gap alignCenter mt-3">
                                        <input type="checkbox" checked={announcementData?.audience?.includes("staff") || announcementData?.audience?.includes("all")} onChange={(e) => {
                                            if (e.target.checked) {
                                                setAnnouncementData({ ...announcementData, audience: [...announcementData.audience, "staff"] })
                                            } else {
                                                setAnnouncementData({ ...announcementData, audience: announcementData.audience.filter((item) => item !== "staff") })
                                            }
                                        }} />
                                        <p className="input-label" style={{ fontWeight: "bold", marginBottom: 0 }}>Staff</p>
                                    </div>
                                    <div className="d-flex flex-row gap alignCenter mt-3">
                                        <input type="checkbox" checked={announcementData?.audience?.includes("members") || announcementData?.audience?.includes("all")} onChange={(e) => {
                                            if (e.target.checked) {
                                                setAnnouncementData({ ...announcementData, audience: [...announcementData.audience, "members"] })
                                            } else {
                                                setAnnouncementData({ ...announcementData, audience: announcementData.audience.filter((item) => item !== "members") })
                                            }
                                        }} />
                                        <p className="input-label" style={{ fontWeight: "bold", marginBottom: 0 }}>Members</p>
                                    </div>
                                    {/* {programData?.membership_tiers?.map((item, index) => {
                                                return (
                                                    <div className="row gap alignCenter mt-10">
                                                        <input type="checkbox" checked={announcementData?.audience?.includes(item.tier_name) || announcementData?.audience?.includes("all")} onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setAnnouncementData({ ...announcementData, audience: [...announcementData.audience, item.tier_name] })
                                                            } else {
                                                                setAnnouncementData({ ...announcementData, audience: announcementData.audience.filter((item) => item !== item.tier_name) })
                                                            }
                                                        }} />
                                                        <p className="input-label" style={{ fontWeight: "bold" }}>{item.tier_name}</p>
                                                    </div>
                                                )
                                            })} */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="buttonContainer mt-4 mb-5 right">
                        {announcementData?.id &&
                            <button className="button" style={{ backgroundColor: "red" }} onClick={() => {
                                deleteBroadcast()
                            }}>Delete</button>}

                        <button className="button" onClick={() => {
                            if (announcementData?.type === "email") {
                                console.log("here")
                                exportHtml()
                            } else {
                                if (announcementData?.id) {
                                    console.log("update1")
                                    updateBroadcast()
                                } else {
                                    createBroadcast()
                                }
                            }

                        }}>Save</button>
                    </div>
                </div>
                <div style={{ width: "100%" }}>
                    <EmailEditor ref={emailEditorRef} onReady={onReady}
                        style={{
                            width: "100%",
                            height: heightAvail,
                        }}
                        options={{
                        }}

                    />
                </div>
            </div>
        </MerchantLayout>
    )
}
