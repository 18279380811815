import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import MerchantHeader from '../../components/MerchantHeader'
import MerchantNavbar from '../../components/MerchantNavbar'
import { ToastContainer, toast } from 'react-toastify';
import config from '../../app-config'
import MerchantLayout from '../../layouts/MerchantLayout';
import { FaSearch } from 'react-icons/fa';

export default function MerchantRewardsPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [rewardData, setRewardData] = useState([])
    const [query, setQuery] = useState("")

    const fetchRewardData = async () => {
        let endpoint = config.endpoint + "/rewards/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (response.status === 200) {
            setRewardData(data.data)
        } else {
            toast.error("Unable to fetch reward data " + data.message)
        }
    }

    useEffect(() => {
        fetchRewardData()
    }, [])

    return (
        <MerchantLayout navSelected={"Rewards"}>
            <div className="component" style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <div className=''>
                    <h2 className="component-title">Rewards</h2>
                </div>
                <button className="btn btn-light" style={{ border: "0.5px solid grey", borderRadius: "5px",  marginRight: "%" }}
                    onClick={() => navigate("/merchant/rewards/register")}
                >Add Reward</button>
            </div>

            <div className="container mt-4">
                <div className='d-flex justify-content-center'>
                    <p className='align-items-center m-4 fw-bold' style={{ fontSize: "1.5em" }}>Search Service list</p>
                </div>
                <div className='d-flex justify-content-center'>
                    <div style={{ position: 'relative', width: '90%' }}>
                        <input type="text" className="text-input align-items-center" style={{ lineHeight: "2", width: "100%", paddingLeft: '30px', borderRadius: '20px' }} placeholder="Search services..." value={query} onChange={(e) => setQuery(e.target.value)} />
                        <FaSearch style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', opacity: '0.5' }} />
                    </div>
                </div>

                <div className="mt-5">
                    {rewardData && rewardData.length > 0 ? (
                        rewardData.map((rewards, index) => {
                            if (JSON.stringify(rewards).toLowerCase().includes(query.toLowerCase())) {
                                return (
                                    <div className="card mt-3" key={index}>
                                        <div className="card-body d-flex justify-content-between align-items-center">
                                            <div>
                                                <p style={{ fontSize: 16 }}>{rewards.name}</p>
                                                <p style={{ color: "gray", fontSize: 12 }}>{rewards.points} points</p>
                                            </div>
                                            <button className="btn btn-light"
                                                onClick={() => navigate("/merchant/rewards/" + rewards.user_id, { state: rewards })}
                                            >Edit</button>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    ) : (
                        <div className='d-flex justify-content-center align-items-center' style={{ marginTop: "5%", fontSize: "1.1em" }}>
                            <p>No rewards available</p>
                        </div>
                    )}
                </div>
            </div>

            
        </MerchantLayout>
    )
}
