import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import MerchantHeader from '../../components/MerchantHeader'
import MerchantNavbar from '../../components/MerchantNavbar'
import config from '../../app-config'
import { ToastContainer, toast } from 'react-toastify';
import MerchantLayout from '../../layouts/MerchantLayout'


export default function MerchantRewardsFormPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    let REWARD_ID = useParams().id || state?.id

    const [showSimilarModal, setShowSimilarModal] = useState(false)
    const [similarUserData, setSimilarUserData] = useState([]) // first_name, last_name, email, contact_number, username
    const [selectedCustomer, setSelectedCustomer] = useState({})

    const [rewardData, setRewardData] = useState(state ? state : {
        name: "",
        description: "",
        points: 100,
        quantity: 100,
        valid_till: new Date(Date.now() + 86400000 * 30),
        valid_from: new Date(),
        image_data: {},
        is_birthday_reward: 0,
        is_new_member_reward: 0,
        status: "active", // active, draft, expired
    })

    const addRewards = async () => {
        let form = new FormData()
        form.append("data", JSON.stringify(rewardData))
        form.append("image_data", rewardData.image_data)

        let endpoint = config.endpoint + "/rewards/"
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            body: form,
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Success!")
            navigate(-1)
        } else {
            toast.error("Unable to create reward " + response.message)
        }
    }

    const updateRewards = async () => {
        let form = new FormData()
        form.append("data", JSON.stringify(rewardData))
        form.append("image_data", rewardData.image_data)
        let endpoint = config.endpoint + "/rewards/"
        let fetchResponse = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            body: form,
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Success!")
            navigate(-1)
        } else {
            toast.error("Unable to update reward " + response.message)
        }
    }

    const deleteRewards = async () => {
        if (!REWARD_ID) return alert.error("Unable to delete customer")
        let endpoint = config.endpoint + "/rewards/" + REWARD_ID
        let fetchResponse = await fetch(endpoint, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Success!")
            navigate(-1)
        } else {
            toast.error("Unable to delete reward " + response.message)
        }
    }

    const tidyDate = (date) => { // Tue May 09 2023 23:01:33 GMT+0800 (Singapore Standard Time)NaN
        let dateObj = new Date(date)
        let year = dateObj.getFullYear()
        let month = dateObj.getMonth() + 1
        let day = dateObj.getDate()
        if (month < 10) month = "0" + month
        if (day < 10) day = "0" + day
        console.log(`${day}-${month}-${year}`)
        return `${year}-${month}-${day}`
    }

    return (
        <>
            <MerchantLayout navSelected={"Rewards"}>
                <div className="component">
                    <p className="component-title">{REWARD_ID === "register" ? "Add Reward" : "Edit Reward"}</p>
                </div>
                <div className="container">
                    <div className="card mt-4">
                        <p className="card-title mb-20">Rewards Information</p>
                        <div className="row gap alignStart justifyBetween" style={{ width: "100%" }}>
                            <div>
                                <div className="row gap">
                                    <div className="input-container">
                                        <label htmlFor="" className="input-label">Name</label>
                                        <input type="text" className="text-input" placeholder="e.g. Free Gift" value={rewardData.name} onChange={(e) => setRewardData({ ...rewardData, name: e.target.value })} />
                                    </div>
                                    <div className="input-container">
                                        <label htmlFor="" className="input-label">Points</label>
                                        <input type="text" className="text-input" placeholder="Points needed" value={rewardData.points} onChange={(e) => setRewardData({ ...rewardData, points: e.target.value })} />
                                    </div>

                                </div>
                                <div className="row gap">
                                    <div className="input-container">
                                        <label htmlFor="" className="input-label">Valid From</label>
                                        <input type="date" className="text-input" placeholder="Valid From" value={rewardData.valid_from} onChange={(e) => setRewardData({ ...rewardData, valid_from: e.target.value })} />
                                    </div>
                                    <div className="input-container">
                                        <label htmlFor="" className="input-label">Valid Till</label>
                                        <input type="date" className="text-input" placeholder="Valid Till" value={rewardData.valid_till} onChange={(e) => setRewardData({ ...rewardData, valid_till: e.target.value })} />
                                    </div>
                                </div>
                                <div className="row gap">
                                    <div className="input-container">
                                        <label htmlFor="" className="input-label">Quantity</label>
                                        <input type="number" className="text-input" placeholder="Quantity" value={rewardData.quantity} onChange={(e) => setRewardData({ ...rewardData, quantity: e.target.value })} />
                                    </div>
                                    <div className="input-container">
                                        <label htmlFor="" className="input-label">Status</label>
                                        <select className="select-input" value={rewardData.status} onChange={(e) => setRewardData({ ...rewardData, status: e.target.value })}>
                                            <option value="active">Active</option>
                                            <option value="draft">Draft</option>
                                            <option value="expired">Expired</option>
                                        </select>
                                    </div>
                                </div>
                                <input type="file" id="image-input" style={{ display: "none" }} onChange={(e) => {
                                    let file = e.target.files[0]
                                    setRewardData({ ...rewardData, image_data: file })
                                }} />
                            </div>
                            <div className="input-container" style={{ width: "550px" }}>
                                <label htmlFor="" className="input-label">Banner Image</label>
                                {rewardData?.image_data?.filePath ?
                                    <img src={`${config.endpoint}${rewardData?.image_data?.filePath}`.replace("./public", "")} alt="" className="image-preview" style={{ width: 500, height: 300, objectFit: "contain", border: "1px solid #ccc", cursor: "pointer" }}
                                        onClick={() => document.getElementById("image-input").click()}
                                    />

                                    :
                                    <div className="image-preview" style={{ width: 500, height: 300, objectFit: "contain", border: "1px solid #ccc", cursor: "pointer" }}
                                        onClick={() => document.getElementById("image-input").click()}
                                    >
                                    </div>
                                }
                            </div>



                        </div>
                        <div className="input-container">
                            <label htmlFor="" className="input-label">Description</label>
                            <textarea type="text" className="textarea-input" placeholder="Description" value={rewardData.description} onChange={(e) => setRewardData({ ...rewardData, description: e.target.value })} />
                        </div>
                    </div>
                    <div className="buttonContainer mt-20 right">
                        {REWARD_ID !== "register" &&
                            <button className="button" onClick={() => {
                                deleteRewards()
                            }}>Delete</button>
                        }
                        <button className="button" onClick={() => {
                            if (REWARD_ID === "register") {
                                addRewards()
                            } else {
                                updateRewards()
                            }
                        }}>Save</button>
                    </div>
                </div>
            </MerchantLayout>
        </>
    )
}
