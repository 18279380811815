import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import MerchantHeader from '../../components/MerchantHeader'
import MerchantNavbar from '../../components/MerchantNavbar'
import { ToastContainer, toast } from 'react-toastify';
import config from '../../app-config'
import MerchantLayout from '../../layouts/MerchantLayout';
import { FaSearch } from 'react-icons/fa';

export default function MerchantAnnouncementPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [announcementData, setAnnouncementData] = useState([])
    const [query, setQuery] = useState("")

    useEffect(() => {
        fetchAnnouncement()
    }, [])

    const fetchAnnouncement = async () => {
        let endpoint = config.endpoint + "/broadcast/"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            setAnnouncementData(response.data)
        } else {
            toast.error("Failed to fetch broadcast")
        }
    }



    return (
        <MerchantLayout 
            navSelected={"Broadcast"}
        >
            <div className="flex-row align-items-center justify-content-between d-flex component">
                <h2 className="component-title">Broadcast</h2>
                <button className="btn btn-light" style={{ border: "0.5px solid grey", borderRadius: "5px", marginRight: "%" }}
                    onClick={() => navigate("/merchant/announcements/create")}
                >Create Broadcast</button>
            </div>

            <div className="container mt-4">
                <div className='d-flex justify-content-center'>
                    <p className='align-items-center m-4 fw-bold' style={{ fontSize: "1.5em" }}>Search Announcements</p>
                </div>
                <div className='d-flex justify-content-center'>
                    <div style={{ position: 'relative', width: '90%' }}>
                        <input type="text" className="text-input align-items-center" style={{ lineHeight: "2", width: "100%", paddingLeft: '30px', borderRadius: '20px' }} placeholder="Search Announcements..." value={query} onChange={(e) => setQuery(e.target.value)} />
                        <FaSearch style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', opacity: '0.5' }} />
                    </div>
                </div>

                <div className='mt-5'>
                    {announcementData && announcementData.length > 0 ? (
                        announcementData && announcementData.map((announcement, index) => {
                            if (JSON.stringify(announcement).toLowerCase().includes(query.toLowerCase())) {
                                return (
                                    <div className="card mt-3">
                                        <div className="card-body d-flex justify-content-between align-items-center">
                                            <div className="">
                                                <p style={{ fontSize: 16 }}>{announcement.internal_name}</p>
                                                <p style={{ fontSize: 12, color: "gray" }}>{announcement.status}</p>
                                            </div>
                                            <button className="btn btn-light"
                                                onClick={() => navigate("/merchant/announcements/" + announcement.id, { state: announcement })}
                                            >Edit</button>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    ) : (
                        <div className='d-flex justify-content-center align-items-center' style={{ marginTop: "5%", fontSize: "1.1em" }}>
                            <p>No announcements created</p>
                        </div>
                    )}
                </div>
            </div>
        </MerchantLayout>
    )
}
