import React from 'react'
import PublicHeader from '../components/PublicHeader'

export default function IndexPage() {

  return (
    <div> 
        <PublicHeader />
        <div className="row">
          <div className="col-lg-6" style={{marginTop:"15%", paddingLeft:"15%"}}>
            <span style={{ fontSize: "0.9em" }}>OurCodeLab Pte Ltd</span>
            <h1>Loyalty Suite Program</h1>
            <p>Unlock Rewards, Embrace Experiences: Your Loyalty, Our Promise.</p>
          </div>
          <div className="col-lg-5 justify-content-center">
            <img src="/home-page-pic.jpeg" alt="" className="img-fluid mt-3" />
          </div>
        </div>
    </div>
  )
}
