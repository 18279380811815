import React, {useEffect, useState} from 'react'
import AdminHeader from '../../components/AdminHeader'
import AdminNavbar from '../../components/AdminNavbar'
import { useLocation, useNavigate } from 'react-router'
import { ToastContainer, toast } from 'react-toastify';
import config from '../../app-config'


export default function AdminDocumentationPage() {
    const navigate = useNavigate()
    const { state } = useLocation()

    return (
        <div style={{ width: "100%" }}>
            <ToastContainer/>
            <div className="row">
                <div className='col-lg-1'>
                    <AdminNavbar selected={"Dashboard"} />
                </div>

                <div className='col-lg-11'>
                    <AdminHeader />
                    <div className="row mt-4 mb-3 component">
                        <div className='col-lg-6'>
                            <h2 className="component-title">Admin Documentation</h2>
                        </div>
                    </div>
                    <div className="container">
                      
                    </div>
                </div>
            </div>
        </div>
    )
}
