import React, {useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ToastContainer, toast } from 'react-toastify';
import config from '../../app-config'
import MerchantHeader from '../../components/MerchantHeader'
import MerchantNavbar from '../../components/MerchantNavbar'
import MerchantLayout from '../../layouts/MerchantLayout';

export default function AdminIndexPage() {
    const navigate = useNavigate()
    const { state } = useLocation()

    return (
        <MerchantLayout>
            <div className="d-flex justify-content-between flex-row component">
                <h2 className="component-title" style={{ margin: 0, padding: 0 }}>Merchant Documentation</h2>
            </div>
            <div className='container mt-4'>
                <div className='d-flex justify-content-center'>
                    <p className='align-items-center m-4 fw-bold' style={{ fontSize: "1.2em" }}>How can we help you today?</p>
                </div>

                <div 
                className='row gap-4 justify-content-center mx-auto'
                style={{ marginTop: "2%" }}>
                    <div className="col-lg-5">
                        <div className="card mx-auto" style={{ width: "85%", paddingTop: '12%', paddingBottom: '12%' }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="60"
                            height="60"
                            fill="currentColor"
                            className="bi bi-briefcase-fill text-center mx-auto mt-3"
                            viewBox="0 0 16 16"
                        >
                            <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5"/>
                            <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85z"/>
                        </svg>
                        <div className="card-body">
                            <p className="card-text" style={{ fontSize: "1.5em", textAlign:"center" }}>
                                Manage my business
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="card mx-auto" style={{ width: "85%", paddingTop: '12%', paddingBottom: '12%' }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="60"
                            height="60"
                            fill="currentColor"
                            className="bi bi-gift-fill text-center mx-auto mt-3"
                            viewBox="0 0 16 16"
                        >  
                            <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A3 3 0 0 1 3 2.506zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43zM9 3h2.932l.023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0zm6 4v7.5a1.5 1.5 0 0 1-1.5 1.5H9V7zM2.5 16A1.5 1.5 0 0 1 1 14.5V7h6v9z"/>
                        </svg>
                        <div className="card-body">
                            <p className="card-text" style={{ fontSize: "1.5em", textAlign:"center" }}>
                                Rewards & loyalty cards
                            </p>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
            
        </MerchantLayout>
    )
}
