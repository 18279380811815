import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ToastContainer, toast } from 'react-toastify';
import AdminHeader from '../components/AdminHeader'
import AdminNavbar from '../components/AdminNavbar'
import config from '../app-config';


export default function AdminLayout({ children, navSelected }) {
    const navigate = useNavigate()
    const { state } = useLocation()

    useEffect(() => {
        let component = document.querySelector(".component")
        try {

            component.style.top = document.getElementById("app-header").offsetHeight + "px"
            component.style.position = "sticky"
            component.style.zIndex = 100
        } catch (error) {
        }
    }, [])

    return (
        <div>
            <ToastContainer />
            <div className="d-flex" style={{ width: "100%", alignItems: "flex-start", flexDirection: "row", }}>
                <div className='' style={{ maxWidth: "80px" }}>
                    <AdminNavbar selected={navSelected} />
                </div>
                <div className='' style={{ maxHeight: "100vh", overflowY: "scroll", width: "100%" }}>
                    <AdminHeader />
                    {children}
                </div>
            </div>
        </div>
    )
}
