import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import MerchantHeader from '../../components/MerchantHeader'
import MerchantNavbar from '../../components/MerchantNavbar'
import { ToastContainer, toast } from 'react-toastify';
import config from '../../app-config'
import MerchantLayout from '../../layouts/MerchantLayout';


export default function MerchantIndexPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [name, setName] = useState("")


    useEffect(() => {
        setName(JSON.parse(window.localStorage.getItem("data"))?.first_name)
        getSchedule()
    }, [])


    const [schedulerData, setSchedulerData] = useState([])

    const getSchedule = async () => {
        let endpoint = config.endpoint + "/bookings/merchant/" + JSON.parse(window.localStorage.getItem("data"))?.id
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let data = await response.json()
        if (response.status === 200) {
            // setSelectedSchedule()
            setSchedulerData(data.data)
        } else {
            toast.error("Unable to get schedule " + data.message)
        }
    }

    const tidyDate = (date) => {
        let newDate = new Date(date)
        return newDate.toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
        })
    }



    return (
        <MerchantLayout navSelected={"Dashboard"}>
            <div className="container p-2 " style={{ marginTop: "3%" }}>
                <div className="card">
                    <div className="row justify-content-between align-items-start mt-3 mb-3 p-4">
                        <div className="col-lg-6">
                            <h1 className="display-4 font-weight-bold">Hello {name},</h1>
                            <p className="mt-2">What would you like to do today?</p>
                            <div className="row align-items-center mt-5 mb-5 gap-3 d-flex justify-content-center ">
                                <button className="btn btn-primary w-75 "
                                    onClick={() => {
                                        navigate("/merchant/rewards/dashboard")
                                    }}
                                >Rewards Dashboard</button>
                                <button className="btn btn-primary w-75"
                                    onClick={() => {
                                        navigate("/merchant/customer")
                                    }}
                                >View Customers</button>
                                <button className="btn btn-primary w-75"
                                    onClick={() => {
                                        navigate("/merchant/bookings")
                                    }}
                                >Today's Bookings</button>
                            </div>

                        </div>
                        <div className="col-lg-6">
                            <img src="/img.jpg" alt="" className="img-fluid mt-3" />
                        </div>
                    </div>

                        <div className="row justify-content-between align-items-start mt-1 mb-4 p-4">
                            <div className="card mt-3">
                                <h2 className="card-title">Today's Appointment Schedule</h2>
                                <div className="mt-2">
                                    {schedulerData.length > 0 ? schedulerData?.map((data, index) => {
                                        let color = "gray"
                                        if (data?.booking_status === "Confirmed") {
                                            color = "green"
                                        } else if (data?.booking_status === "Pending") {
                                            color = "orange"
                                        } else if (data?.booking_status === "Cancelled") {
                                            color = "red"
                                        }
                                        return (
                                            <div className="card-body d-flex justify-content-between align-items-center mt-2" style={{ border: '0.5px solid grey' }} key={index}>
                                                <div>
                                                    <h3 className='card-title'>{data?.title}</h3>
                                                    <p className=''>{tidyDate(data?.startDate)} - {tidyDate(data?.endDate)}</p>
                                                </div>
                                                <p className="p-2 bg-primary text-white font-weight-bold">{data?.booking_status}</p>
                                            </div>
                                        )
                                    }) :
                                        <p>No bookings today</p>
                                    }
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </MerchantLayout>
    )
}
