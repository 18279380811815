import React, { useEffect, useState } from 'react'
import AdminHeader from '../../components/AdminHeader'
import AdminNavbar from '../../components/AdminNavbar'
import { useLocation, useNavigate, useParams } from 'react-router'
import { IoAddSharp, IoClose } from 'react-icons/io5'
import config from '../../app-config'
import { ToastContainer, toast } from 'react-toastify';
import AdminLayout from '../../layouts/AdminLayout';

export default function AdminCompanyFormPage() {
    const navigate = useNavigate()
    const { state } = useLocation()

    const COMPANY_ID = useParams().id || state?.id
    console.log({COMPANY_ID})

    const [companyData, setCompanyData] = useState(state ? state : {
        name: "",
        code: "",
        admin_email: [""],
        admin_mobile: [""],
        remarks: "",
        id:"",
    })

    const registerCompany = async () =>{
        let endpoint = config.endpoint+"/company/"
        let fetchResponse = await fetch(endpoint,{
            method:"POST",
            credentials:"include",
            headers:{
                "Content-Type":"application/json",
              
            },
            body: JSON.stringify(companyData)
        })
        let response = await fetchResponse.json()
        if (response.status === 200){
            toast.success("Success!")
            navigate(-1)
        }else{
            toast.error("Unable to register company "+ response.message)
        }
    }

    const updateCompany = async () =>{
        let endpoint = config.endpoint+"/company/"
        let fetchResponse = await fetch(endpoint,{
            method:"PUT",
            credentials:"include",
            headers:{
                "Content-Type":"application/json",
                
            },
            body: JSON.stringify(companyData)
        })
        let response = await fetchResponse.json()
        if (response.status === 200){
            toast.success("Success!")
            navigate(-1)
        }else{
            toast.error("Unable to update company "+ response.message)
        }
    }   

    const deleteCompany = async () =>{
        if (!COMPANY_ID) return toast.error("Invalid Company ID")
        let endpoint = config.endpoint+"/company/" + COMPANY_ID
        let fetchResponse = await fetch(endpoint,{
            method:"DELETE",
            credentials:"include",
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 200){
            toast.success("Success!")
            navigate(-1)
        }else{
            toast.error("Unable to delete company "+ response.message)
        }
    }

    return (
        <AdminLayout navSelected={"Company"}>
            <div className="component">
                <p className='component-title'>{COMPANY_ID === "register" ? "Add a new Client" : "Edit Client's Company"}</p>
            </div>
            <div className="container mt-4">
                <div className="row justifyBetween gap" style={{ marginTop: 10 }}>
                    <div className="card">
                        <p className="card-title mb-10">Company Details</p>
                        <div className="row gap justifyBetween">
                            <div className="input-container" style={{ width: "70%" }}>
                                <p className="input-label">Company Name</p>
                                <input className="text-input" type="text" placeholder="Company Name" value={companyData?.name} onChange={(e) => setCompanyData({ ...companyData, name: e.target.value })} />
                            </div>
                            <div className="input-container" style={{ width: "30%" }}>
                                <p className="input-label">Company Code</p>
                                <input className="text-input" type="text" placeholder="Company Code" disabled={companyData?.id !== ""} value={companyData?.code} onChange={(e) => setCompanyData({ ...companyData, code: e.target.value })} />
                            </div>
                        </div>
                        <div className="input-container">
                            <p className="input-label">Remarks</p>
                            <textarea className="textarea-input" type="text" placeholder="Remarks" value={companyData?.remarks} onChange={(e) => setCompanyData({ ...companyData, remarks: e.target.value })} />
                        </div>
                    </div>
                </div>
                <div className="row justifyBetween gap" style={{ marginTop: 10 }}>
                    <div className="card">
                        <div className="row justifyBetween alignCenter">
                            <p className="card-title">Email Addresses</p>
                            <button className='backgroundLess mb-3'
                                style={{ minWidth: 50, display: 'flex', alignItems: 'center' }}
                                onClick={() => {
                                    setCompanyData({ ...companyData, admin_email: [...companyData?.admin_email, ""] })
                                }}
                            >{<IoAddSharp size={20} />} Add Email</button>
                        
                        {companyData?.admin_email?.map((email, i) => (
                            <div className="alignCenter mb-10" style={{ display: "flex", gap: 10 }}>
                                <div className="input-container">
                                    <input className="text-input" type="text" placeholder="Email Address" value={email}
                                        onChange={(e) => {
                                            let temp = companyData?.admin_email
                                            temp[i] = e.target.value.replaceAll(" ", "").toLowerCase()
                                            setCompanyData({ ...companyData, admin_email: temp })
                                        }} />
                                </div>
                                <button
                                    className='backgroundLess'
                                    style={{ minWidth: 50 }}
                                    onClick={() => {
                                        if (companyData?.admin_email.length === 1) return
                                        let temp = companyData?.admin_email
                                        temp.splice(i, 1)
                                        setCompanyData({ ...companyData, admin_email: temp })
                                    }}
                                >{<IoClose size={30} />}</button>
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className="card">
                        <div className="row justifyBetween alignCenter">
                            <p className="card-title">Mobile Number</p>
                            <button className='backgroundLess mb-3'
                                onClick={() => {
                                    setCompanyData({ ...companyData, admin_mobile: [...companyData?.admin_mobile, ""] })
                                }}
                                style={{ minWidth: 50, display: 'flex', alignItems: 'center' }}
                            >{<IoAddSharp size={20}/>} Add Mobile</button>
                        </div>
                        {companyData?.admin_mobile?.map((mobile, i) => (
                            <div className="alignCenter mb-10" style={{ display: "flex", gap: 10 }}>
                                <div className="input-container">
                                    <input className="text-input" type="tel" placeholder="Mobile Number" value={mobile}
                                    onChange={(e) => {
                                        let temp = companyData?.admin_mobile
                                        temp[i] = e.target.value.replaceAll(" ", "").toLowerCase()
                                        setCompanyData({ ...companyData, admin_mobile: temp })
                                    }} /> 
                                </div>
                                
                                <button
                                    className='backgroundLess'
                                    style={{ minWidth: 50 }}
                                    onClick={() => {
                                        if (companyData?.admin_mobile.length === 1) return
                                        let temp = companyData?.admin_mobile
                                        temp.splice(i, 1)
                                        setCompanyData({ ...companyData, admin_mobile: temp })
                                    }}
                                >{<IoClose size={30} />}</button>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="buttonContainer mt-3 mb-4 right">
                    {COMPANY_ID !== "register" && <button className="button" style={{backgroundColor:"red"}} onClick={() => {
                        deleteCompany()
                    }}>Delete</button>}
                    <button className="button" onClick={() => {
                        if (COMPANY_ID === "register") {
                            // Register
                            registerCompany()
                        } else {
                            // Update
                            updateCompany()
                        }
                    }}>Save</button>
                </div>
            </div>
        </AdminLayout>
    )
}
