import React, { useEffect, useState } from 'react'
import AdminHeader from '../../components/AdminHeader'
import AdminNavbar from '../../components/AdminNavbar'
import { useLocation, useNavigate, useParams } from 'react-router'
import { IoAddSharp, IoClose } from 'react-icons/io5'
import config from '../../app-config'
import { ToastContainer, toast } from 'react-toastify';
import MerchantNavbar from '../../components/MerchantNavbar'
import MerchantHeader from '../../components/MerchantHeader'
import MerchantLayout from '../../layouts/MerchantLayout'


export default function MerchantCompanyFormPage() {
    const navigate = useNavigate()
    const { state } = useLocation()

    const COMPANY_ID = useParams().id || state?.id

    const [companyData, setCompanyData] = useState(state ? state : {
        name: "",
        code: "",
        admin_email: [""],
        admin_mobile: [""],
        remarks: "",
        id: "",
    })

    const updateCompany = async () => {
        let endpoint = config.endpoint + "/company/merchant/"
        let fetchResponse = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",

            },
            body: JSON.stringify(companyData)
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Success!")
            navigate(-1)
        } else {
            toast.error("Unable to update company " + response.message)
        }
    }

    useEffect(() => {
        fetchBusinessData()
    }, [])

    const fetchBusinessData = async () => {
        let endpoint = config.endpoint + "/company/profile"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setCompanyData(data?.data?.company)
        } else {
            toast.error(data.message)
        }
    }

    return (
        <MerchantLayout navSelected={"My Business"}>
            <div className="component mb-3" >
                <h2 className='component-title'>Edit Company</h2>
            </div>
            <div className="container">
                <div className="card mb-3" style={{ padding: 0, margin: 0 }}>
                    <h5 className="card-header mb-2">Company Details</h5>
                    <div className="card-body">
                        <div className=" mb-3">
                            <label className="form-label">Company Name</label>
                            <input className="form-control" type="text" placeholder="Company Name" value={companyData?.name} onChange={(e) => setCompanyData({ ...companyData, name: e.target.value })} />
                        </div>
                        <div className=" mb-3">
                            <label className="form-label">Company Code</label>
                            <input className="form-control" type="text" placeholder="Company Code" disabled={companyData?.id !== ""} value={companyData?.code} onChange={(e) => setCompanyData({ ...companyData, code: e.target.value })} />
                        </div>
                        <div className=" mb-3">
                            <label className="form-label">Remarks</label>
                            <textarea className="form-control" type="text" placeholder="Remarks" value={companyData?.remarks} onChange={(e) => setCompanyData({ ...companyData, remarks: e.target.value })} />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row mb-3 gap-3">
                    <div className="card" style={{ padding: 0, margin: 0 }}>
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5 style={{ padding: 0, margin: 0 }} >Email Addresses</h5>
                            <button className="d-flex btn btn-light align-items-center"
                                onClick={() => {
                                    setCompanyData({ ...companyData, admin_email: [...companyData?.admin_email, ""] })
                                }}
                            ><IoAddSharp /> Add Email</button>
                        </div>
                        <div className="card-body">
                            {companyData?.admin_email?.map((email, i) => (
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <input className="form-control" type="text" placeholder="Email Address" value={email}
                                        onChange={(e) => {
                                            let temp = companyData?.admin_email
                                            temp[i] = e.target.value.replaceAll(" ", "").toLowerCase()
                                            setCompanyData({ ...companyData, admin_email: temp })
                                        }} />
                                    <button
                                        className='btn btn-danger btn-sm'
                                        onClick={() => {
                                            if (companyData?.admin_email.length === 1) return
                                            let temp = companyData?.admin_email
                                            temp.splice(i, 1)
                                            setCompanyData({ ...companyData, admin_email: temp })
                                        }}
                                    ><IoClose /></button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="card" style={{ padding: 0, margin: 0 }}>
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5 style={{ padding: 0, margin: 0 }}>Mobile Number</h5>
                            <button className="d-flex btn btn-light align-items-center"
                                onClick={() => {
                                    setCompanyData({ ...companyData, admin_mobile: [...companyData?.admin_mobile, ""] })
                                }}
                            ><IoAddSharp /> Add Mobile</button>
                        </div>
                        <div className="card-body">
                            {companyData?.admin_mobile?.map((mobile, i) => (
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <input className="form-control" type="tel" placeholder="Mobile Number" value={mobile}
                                        onChange={(e) => {
                                            let temp = companyData?.admin_mobile
                                            temp[i] = e.target.value.replaceAll(" ", "").toLowerCase()
                                            setCompanyData({ ...companyData, admin_mobile: temp })
                                        }} />
                                    <button
                                        className='btn btn-danger btn-sm'
                                        onClick={() => {
                                            if (companyData?.admin_mobile.length === 1) return
                                            let temp = companyData?.admin_mobile
                                            temp.splice(i, 1)
                                            setCompanyData({ ...companyData, admin_mobile: temp })
                                        }}
                                    ><IoClose /></button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end mb-3 mt-3">
                    <button className="btn btn-primary" onClick={() => {
                        updateCompany()
                    }}>Save</button>
                </div>
            </div>
        </MerchantLayout>
    )
}
