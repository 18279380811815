import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import MerchantHeader from '../../components/MerchantHeader'
import MerchantNavbar from '../../components/MerchantNavbar'
import { ToastContainer, toast } from 'react-toastify';
import config from '../../app-config'
import Select from 'react-select'
import MerchantLayout from '../../layouts/MerchantLayout';


export default function MerchantRewardsDashboardPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [customerData, setCustomerData] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState({})
    const [membershipData, setMembershipData] = useState({})
    const [transactionLogsData, setTransactionLogsData] = useState([])
    const [companyServices, setCompanyServices] = useState([])
    const [rewardData, setRewardData] = useState([])
    const [userWallet, setUserWallet] = useState([])
    const [programData, setProgramData] = useState({
        starting_points: 0,
        points_per_visit: 0,
        points_per_dollar: 10,
        membership_duration: 365,
        membership_expiration: 0, //days since membership duration
        membership_tiers: [],// {tier_name: "Gold", tier_points: 1000,}
        is_forever: 0,
        renewable_points: 0,
        starting_stamps: 0,
        max_stamps: 12,
        milestone: [], //{milestone_points: 100, milestone_reward: "Free Drink", status: "unclaimed",}
        card_duration: 12, //months
        card_name: "Loyalty Card",
    })

    const [transactionData, setTransactionData] = useState({
        amount: 0,
        remarks: "",
        user_id: "",
        points_earned: 0,
        company_id: "",
        timestamp: "",
        service_id: "",
        stamps: 0,
    })

    const fetchConfig = async () => {
        let endpoint = config.endpoint + "/loyalty/config"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setProgramData(data.data)
        } else {
            toast.error("Failed to fetch loyalty program configuration")
        }
    }

    const fetchCustomerData = async () => {
        let endpoint = config.endpoint + "/customer/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setCustomerData(data.data)
        } else {
            toast.error("Unable to fetch customer data " + data.message)
        }
    }

    const fetchRewardData = async () => {
        let endpoint = config.endpoint + "/rewards/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setRewardData(data.data)
        } else {
            toast.error("Unable to fetch reward data " + data.message)
        }
    }

    useEffect(() => {
        reloadPage()
    }, [selectedCustomer])

    const fetchCustomerMembership = async () => {
        if (!selectedCustomer?.id) return
        let endpoint = config.endpoint + "/customer/membership/" + selectedCustomer.id
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            console.log(data.data)
            setMembershipData(data?.data?.membership)
        } else {
            toast.error("Unable to fetch customer membership " + data.message)
        }
    }

    const fetchUserTransaction = async () => {
        if (!selectedCustomer?.id) return
        let endpoint = config.endpoint + "/transaction/user/" + selectedCustomer?.id
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setTransactionLogsData(data?.data)
        } else {
            toast.error("Unable to fetch customer transaction " + data.message)
        }
    }


    const submitUserTransaction = async () => {
        if (!transactionData?.timestamp) return

        if (!selectedCustomer?.id) return
        let endpoint = config.endpoint + "/transaction/"
        let response = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user: selectedCustomer,
                ...transactionData
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            reloadPage()
            toast.success("Successfully submitted customer transaction")
        } else {
            toast.error("Unable to submit customer transaction " + data.message)
        }
    }

    const redeemRewards = async (reward) => {
        if (!selectedCustomer?.id) return
        let endpoint = config.endpoint + "/transaction/redeem"
        let response = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user: selectedCustomer,
                ...reward
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            reloadPage()
            toast.success("Successfully submitted customer transaction")
        } else {
            toast.error("Unable to submit customer transaction " + data.message)
        }
    }

    const getUserWallet = async () => {
        if (!selectedCustomer?.id) return
        let endpoint = config.endpoint + "/transaction/redeem/wallet/" + selectedCustomer?.id
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let data = await response.json()
        if (data.status === 200) {
            console.log(data.data)
            setUserWallet(data.data)
        } else {
            toast.error("Unable to submit customer transaction " + data.message)
        }
    }

    const submitUseReward = async (reward) => {
        if (!selectedCustomer?.id) return
        let endpoint = config.endpoint + "/transaction/redeem/use"
        let response = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user: selectedCustomer,
                ...reward
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            reloadPage()
            toast.success("Successfully redeemed reward")
        } else {
            toast.error("Unable to redeem reward " + data.message)
        }
    }

    const updateUserTransaction = async () => {
        if (!selectedCustomer?.id) return
        let endpoint = config.endpoint + "/transaction/"
        let response = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...selectedCustomer,
                ...transactionData
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            reloadPage()
            toast.success("Successfully updated customer transaction")
        } else {
            toast.error("Unable to update customer transaction " + data.message)
        }
    }

    const deleteUserTransaction = async () => {

        if (!transactionData?.id) return
        let endpoint = config.endpoint + "/transaction/user/" + transactionData?.id
        let response = await fetch(endpoint, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...transactionData
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            toast.success("Successfully deleted customer transaction")
            fetchUserTransaction()
            reloadPage()
            setTransactionData()
        } else {
            toast.error("Unable to delete customer transaction " + data.message)
        }
    }


    const fetchCompanyServices = async () => {
        let endpoint = config.endpoint + "/service/merchant/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setCompanyServices(data.data)
        } else {
            toast.error(data.message)
        }
    }

    const addCommas = (num) => {
        return new Intl.NumberFormat().format(num)
    }

    const reloadPage = () => {
        fetchCustomerData()
        fetchRewardData()
        fetchCustomerMembership()
        fetchUserTransaction()
        fetchConfig()
        getUserWallet()
    }

    useEffect(() => {
        fetchCompanyServices()
    }, [])

    useEffect(() => {
        fetchCustomerMembership()
    }, [selectedCustomer])


    useEffect(() => {
        setTransactionData({
            ...transactionData,
            points_earned: Math.floor(transactionData?.amount * programData?.points_per_dollar),
        })
    }, [transactionData?.amount])

    const tidyDate = (date) => {
        let d = new Date(date)

        let year = d.getFullYear()
        let month = d.getMonth() + 1
        let day = d.getDate()

        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        let monthString = months[month - 1]

        return `${day} ${monthString} ${year}`
    }


    return (
        <MerchantLayout navSelected={"My Business"}>
            <div className="component flex-row d-flex align-items-center">
                <div className="col-lg-8">
                    <h2 className="component-title">Customer Rewards Redemption</h2>
                </div>
                <div className="col-lg-4">
                    <div className="form-group" >
                        <label>Client</label>
                        <Select
                            styles={{ width: "50%" }}
                            value={{ value: selectedCustomer.id, label: `${selectedCustomer.first_name} ${selectedCustomer.last_name}`, payload: selectedCustomer }}
                            onChange={(e) => {
                                setSelectedCustomer(e.payload)
                            }}
                            options={customerData.map((customer) => {
                                return (
                                    { value: customer.id, label: `${customer.first_name} ${customer.last_name}`, payload: customer }
                                )
                            })}
                        />
                    </div>
                </div>


            </div>
            <div className="container-fluid">
                <div className="row p-5 justify-content-between">
                    <div className="col-lg-8">
                        <div className="card" style={{ padding: 0, margin: 0 }}>
                            <h5 className="card-header mb-3" style={{}}>Add Transaction</h5>
                            <div style={{ padding: 10 }}>
                                <div className="row">
                                    <div className="form-group col-6">
                                        <label>Date</label>
                                        <input type="date" className="form-control" value={transactionData?.timestamp} onChange={(e) => {
                                            setTransactionData({ ...transactionData, timestamp: e.target.value })
                                        }} />
                                    </div>
                                    <div className="form-group col-6">
                                        <label>Service</label>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? 'grey' : 'red',
                                                    padding: "3px",
                                                    borderRadius: "5px",
                                                    fontSize: "15px",
                                                    fontWeight: "500",
                                                }),
                                            }}
                                            value={{ value: transactionData?.service_id, label: companyServices.filter(service => service.id == transactionData?.service_id)[0]?.name }}
                                            onChange={(e) => {
                                                setTransactionData({ ...transactionData, service_id: e.value })
                                            }}
                                            options={companyServices.map((service) => {
                                                return (
                                                    { value: service.id, label: service.name }
                                                )
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="row gap">
                                    <div className="input-container">
                                        <label htmlFor="" className="input-label">Amount</label>
                                        <input type="number" className="text-input" value={transactionData?.amount} onChange={(e) => {
                                            setTransactionData({ ...transactionData, amount: e.target.value })
                                        }} />
                                    </div>
                                    <div className="input-container">
                                        <label htmlFor="" className="input-label">Points</label>
                                        <input type="number" className="text-input" value={transactionData?.points_earned} onChange={(e) => {
                                            setTransactionData({ ...transactionData, points_earned: e.target.value })
                                        }} />
                                    </div>
                                    <div className="input-container">
                                        <label htmlFor="" className="input-label">Stamps</label>
                                        <input type="number" className="text-input" value={transactionData?.stamps} onChange={(e) => {
                                            setTransactionData({ ...transactionData, stamps: e.target.value })
                                        }} />
                                    </div>
                                </div>
                                <div className="input-container">
                                    <label htmlFor="" className="input-label">Remarks</label>
                                    <textarea type="text" className="text-input" value={transactionData?.remarks} onChange={(e) => {
                                        setTransactionData({ ...transactionData, remarks: e.target.value })
                                    }} />
                                </div>
                                <div className="buttonContainer mt-10 right">
                                    <button className="backgroundLess"
                                        onClick={() => {
                                            setTransactionData()
                                        }}
                                    >Clear</button>
                                    {!transactionData?.id ? <button className="button"
                                        onClick={() => {
                                            submitUserTransaction()
                                        }}
                                    >Add</button> :
                                        <>
                                            <button className="button"
                                                onClick={() => {
                                                    deleteUserTransaction()
                                                }}
                                            >Delete</button>
                                            <button className="button"
                                                onClick={() => {
                                                    updateUserTransaction()
                                                }}
                                            >Update</button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <div className="card" style={{ padding: 0, margin: 0 }}>
                                    <h5 className="card-header">Past Transaction</h5>
                                    <div style={{ padding: 10 }}>
                                        {transactionLogsData.map((transaction) => {
                                            return (
                                                <div className="card mb-2">
                                                    <div className="card-body">
                                                        <h6 className="card-subtitle mb-2 text-muted">{tidyDate(transaction?.timestamp).toUpperCase()}</h6>
                                                        <p className="card-text">${transaction?.amount} • {transaction?.points_earned} points</p>
                                                        <button className="btn btn-link"
                                                            onClick={() => {
                                                                setTransactionData(transaction)
                                                            }}
                                                        >Edit</button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card" style={{ padding: 0, margin: 0 }}>
                                    <h5 className="card-header">Rewards Available</h5>
                                    <div style={{ padding: 10 }}>
                                        {rewardData.map((reward) => {
                                            let date = new Date()
                                            let valid_from = new Date(reward.valid_from)
                                            let valid_to = new Date(reward.valid_till)

                                            if (date >= valid_from && date <= valid_to && reward.points <= membershipData.loyalty_points) {
                                                return (
                                                    <div className="card mb-2">
                                                        <div className="card-body d-flex justify-content-between align-items-center">
                                                            <div>
                                                                <h6 className="card-subtitle mb-2 text-muted">{reward.name}</h6>
                                                                <p className="card-text">{reward.points} points</p>
                                                            </div>
                                                            <button className="btn btn-primary"
                                                                onClick={() => {
                                                                    redeemRewards(reward)
                                                                }}
                                                            >
                                                                Redeem
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card mb-3" style={{ padding: 0, margin: 0 }}>
                            <h5 className="card-header">User Details</h5>
                            <div className="card-body">
                                <p className="card-text">{selectedCustomer?.first_name} {selectedCustomer?.last_name}</p>
                                <p className="card-text">{selectedCustomer?.email}</p>
                                <p className="card-text">{selectedCustomer?.contact_number}</p>
                                <p className="card-text">{selectedCustomer?.account_remarks}</p>
                            </div>
                        </div>
                        <div className="card mb-3" style={{ padding: 0, margin: 0 }}>
                            <h5 className="card-header" >Membership Details</h5>
                            <div className="card-body">
                                <p className="card-text font-weight-bold">{membershipData.loyalty_points} points</p>
                                <p className="card-text">{programData?.membership_tiers?.filter(data => parseInt(data.tier_points) <= parseFloat(membershipData.loyalty_points))[programData?.membership_tiers?.filter(data => parseInt(data.tier_points) <= parseFloat(membershipData.loyalty_points))?.length - 1]?.tier_name}</p>
                                {membershipData?.is_forever == 1 ?
                                    <p className="card-text">Lifetime Membership</p>
                                    :
                                    <>
                                        <p className="card-text">Renewal on {tidyDate(membershipData?.renewal_date)}</p>
                                        <p className="card-text">Expiring {tidyDate(membershipData?.expiration_date)}</p>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="card mb-3" style={{ padding: 0, margin: 0 }}>
                            <h5 className="card-header" >Member's Wallet</h5>
                            {userWallet.map((wallet) => {
                                return (
                                    <div className="card mb-2">
                                        <div className="card-body">
                                            <h6 className="card-subtitle mb-2 text-muted">{wallet?.reward_data?.name}</h6>
                                            <button className="btn btn-link"
                                                onClick={() => {
                                                    submitUseReward(wallet)
                                                }}
                                            >Use</button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </MerchantLayout>
    )
}
