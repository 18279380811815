import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ToastContainer, toast } from 'react-toastify';
import config from '../../app-config'
import MerchantHeader from '../../components/MerchantHeader'
import MerchantNavbar from '../../components/MerchantNavbar'
import { IoAddSharp, IoClose, IoDocument } from 'react-icons/io5'
import Switch from 'react-switch'
import 'bootstrap'
import MerchantLayout from '../../layouts/MerchantLayout';


export default function MerchantLoyaltyConfigurationPage() {
    const navigate = useNavigate()
    const { state } = useLocation()

    const [programData, setProgramData] = useState({
        starting_points: 0,
        points_per_visit: 0,
        points_per_dollar: 10,
        membership_duration: 365,
        membership_expiration: 0, //days since membership duration
        membership_tiers: [],// {tier_name: "Gold", tier_points: 1000,}
        is_forever: 0,
        renewable_points: 0,
        starting_stamps: 0,
        max_stamps: 12,
        milestone: [], //{milestone_points: 100, milestone_reward: "Free Drink", status: "unclaimed",}
        card_duration: 12, //months
        card_name: "Loyalty Card",
        policy: [] //{policy_name, policy_active, policy_content}
    })

    const [selectedPolicy, setSelectedPolicy] = useState()


    useEffect(() => {
        fetchConfig()
    }, [])

    const fetchConfig = async () => {
        let endpoint = config.endpoint + "/loyalty/config"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (response.status === 200) {
            setProgramData(data.data)
        } else {
            toast.error("Failed to fetch loyalty program configuration")
        }
    }

    const updateConfig = async () => {
        let endpoint = config.endpoint + "/loyalty/config"
        let response = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...programData
            })
        })
        let data = await response.json()
        if (response.status === 200) {
            toast.success("Loyalty Program Configuration Updated")
        } else {
            toast.error("Failed to update loyalty program configuration")
        }
    }

    return (
        <MerchantLayout navSelected={"My Business"} >
            <div>
                <div className="component mb-3">
                    <p className="component-title">Loyalty Program Configuration</p>
                </div>
                <div className="container">
                    <div className="gap rows mb-3">
                        <div className="card mb-3" style={{ padding: 0, margin: 0 }}>
                            <div class="card-header">
                                <button class="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePoints">
                                    <span style={{ fontSize: "1.2em", fontWeight: "bolder" }}>Points</span>
                                </button>
                            </div>
                            <div class="collapse" id="collapsePoints">
                                <div class="card-body">
                                    <div className="input-container">
                                        <p className="input-label">Starting Points <span>(new members)</span></p>
                                        <input className="text-input" type="number" value={programData.starting_points} onChange={(e) => setProgramData({ ...programData, starting_points: e.target.value })} />
                                    </div>
                                    <div className="input-container">
                                        <p className="input-label">Points per Visit</p>
                                        <input className="text-input" type="number" value={programData.points_per_visit} onChange={(e) => setProgramData({ ...programData, points_per_visit: e.target.value })} />
                                    </div>
                                    <div className="input-container">
                                        <p className="input-label">Points per Dollar</p>
                                        <input className="text-input" type="number" value={programData.points_per_dollar} onChange={(e) => setProgramData({ ...programData, points_per_dollar: e.target.value })} />
                                    </div>
                                    <div className="input-container">
                                        <p className="input-label">Points for Renewal</p>
                                        <input className="text-input" type="number" value={programData.renewable_points} onChange={(e) => setProgramData({ ...programData, renewable_points: e.target.value })} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3" style={{ padding: 0, margin: 0 }}>
                            <div class="card-header">
                                <button class="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMembership">
                                    <span style={{ fontSize: "1.2em", fontWeight: "bolder" }}>Membership Validity</span>
                                </button>
                            </div>
                            <div class="collapse" id="collapseMembership">
                                <div class="card-body">
                                    <div className="rows gap justifyBetween alignStart">
                                        <div className="input-container">
                                            <p className="input-label">Default Membership Duration (days)</p>
                                            <input className="text-input" type="number" value={programData.membership_duration} onChange={(e) => setProgramData({ ...programData, membership_duration: e.target.value })} />
                                        </div>
                                        <div className="input-container d-flex flex-row gap" style={{ display: 'flex', alignItems: 'center' }}>
                                            <p className="input-label" style={{ padding: 0, margin: 0 }}>Lifetime Membership</p>
                                            <input className="checkbox-input" type="checkbox" checked={programData.is_forever} onChange={(e) => setProgramData({ ...programData, is_forever: e.target.checked })} />
                                        </div>
                                    </div>
                                    <div className="input-container">
                                        <p className="input-label">Membership Expiration</p>
                                        <p style={{ fontSize: 10 }}>Days since membership duration</p>
                                        <input className="text-input" type="number" value={programData.membership_expiration} onChange={(e) => setProgramData({ ...programData, membership_expiration: e.target.value })} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="card mb-3" style={{ padding: 0, margin: 0 }}>
                            <div className="rows gap justifyBetween alignCenter mb-3">
                                <div class="card-header">
                                    <button class="btn btn-light" type="button">
                                        <span style={{ fontSize: "1.2em", fontWeight: "bolder" }}>Membership Tiers</span>
                                    </button>
                                </div>

                                <button className="backgroundLess row gap alignCenter" style={{ width: "auto" }}
                                    onClick={() => {
                                        let newTier = { tier_name: "New Tier", tier_points: 0 }
                                        if (programData.membership_tiers) {
                                            setProgramData({ ...programData, membership_tiers: [...programData.membership_tiers, newTier] })
                                        } else {
                                            setProgramData({ ...programData, membership_tiers: [newTier] })
                                        }
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: "15%", marginLeft: "25%" }}>
                                        {<IoAddSharp size={20} />}
                                        <span style={{ marginLeft: '5px' }}>Add Tier</span>
                                    </div>

                                </button>
                            </div>

                            {programData?.membership_tiers?.map((tier, index) => {
                                return (
                                    <div className="row gap mt-3" style={{ border: "1px solid grey", padding: "3%", margin: "3%" }}>
                                        <div className="col-lg-12">
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <p className="input-label">Tier {index + 1}</p>
                                                <button className="backgroundLess" style={{ minWidth: 50 }}
                                                    onClick={() => {
                                                        let newTiers = [...programData.membership_tiers]
                                                        newTiers.splice(index, 1)
                                                        setProgramData({ ...programData, membership_tiers: newTiers })
                                                    }}
                                                >
                                                    {<IoClose size={30} />}
                                                </button>
                                            </div>
                                            <div className="input-container" style={{ width: "90%" }}>
                                                <input className="text-input" type="text" value={tier.tier_name} onChange={(e) => {
                                                    let newTiers = [...programData.membership_tiers]
                                                    newTiers[index].tier_name = e.target.value
                                                    setProgramData({ ...programData, membership_tiers: newTiers })
                                                }} />
                                            </div>
                                            <div className="input-container" style={{ width: "80%" }}>
                                                <p className="input-label">Points Required</p>
                                                <input className="text-input" type="number" value={tier.tier_points} onChange={(e) => {
                                                    let newTiers = [...programData.membership_tiers]
                                                    newTiers[index].tier_points = e.target.value
                                                    setProgramData({ ...programData, membership_tiers: newTiers })
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='rows gap justofyBetween mt-10'>
                        <div className="card mb-3" style={{ padding: 0, margin: 0 }}>
                            <div class="card-header">
                                <button class="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCard">
                                    <span style={{ fontSize: "1.2em", fontWeight: "bolder" }}>Loyalty Card</span>
                                </button>
                            </div>
                            <div class="collapse" id="collapseCard">
                                <div class="card-body">
                                    <div className="input-container">
                                        <p className="input-label">Card Name</p>
                                        <input className="text-input" type="text" value={programData.card_name} onChange={(e) => setProgramData({ ...programData, card_name: e.target.value })} />
                                    </div>
                                    <div className="input-container">
                                        <p className="input-label">Starting Stamps</p>
                                        <input className="text-input" type="number" value={programData.starting_stamps} onChange={(e) => setProgramData({ ...programData, starting_stamps: e.target.value })} />
                                    </div>
                                    <div className="input-container">
                                        <p className="input-label">Max Stamps</p>
                                        <input className="text-input" type="number" value={programData.max_stamps} onChange={(e) => setProgramData({ ...programData, max_stamps: e.target.value })} />
                                    </div>
                                    <div className="input-container">
                                        <p className="input-label">Card Duration</p>
                                        <input className="text-input" type="number" value={programData.card_duration} onChange={(e) => setProgramData({ ...programData, card_duration: e.target.value })} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mt-3" style={{ padding: 0, margin: 0 }}>
                            <div className="rows gap justifyBetween alignCenter mb-20">
                                <div class="card-header">
                                    <button class="btn btn-light" type="button">
                                        <span style={{ fontSize: "1.2em", fontWeight: "bolder" }}>Loyalty Card Milestones</span>
                                    </button>
                                </div>
                                <button className="backgroundLess row gap alignCenter" style={{ width: "auto" }}
                                    onClick={() => {
                                        let newMilestone = { milestone_name: "New Milestone", milestone_stamps: 0 }
                                        if (programData?.milestone) {
                                            setProgramData({ ...programData, milestone: [...programData.milestone, newMilestone] })
                                        } else {
                                            setProgramData({ ...programData, milestone: [newMilestone] })
                                        }
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: "15%", marginLeft: "20%" }}>
                                        {<IoAddSharp size={20} />}
                                        <span style={{ marginLeft: '5px' }}>
                                            Add Milestone
                                        </span>
                                    </div>

                                </button>
                            </div>
                            <div>
                                {programData?.milestone?.map((milestone, index) => {
                                    return (
                                        <div className="row gap mt-3" style={{ border: "1px solid grey", padding: "3%", margin: "3%" }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <p className="input-label">Milestone {index + 1}</p>
                                                <button className="backgroundLess" style={{ minWidth: 50 }}
                                                    onClick={() => {
                                                        let newMilestones = [...programData.milestone]
                                                        newMilestones.splice(index, 1)
                                                        setProgramData({ ...programData, milestone: newMilestones })
                                                    }}
                                                >
                                                    {<IoClose size={30} />}
                                                </button>
                                            </div>
                                            <div className="input-container" style={{ width: "90%" }}>
                                                <input className="text-input" type="text" value={milestone.milestone_name} onChange={(e) => {
                                                    let newMilestones = [...programData.milestone]
                                                    newMilestones[index].milestone_name = e.target.value
                                                    setProgramData({ ...programData, milestone: newMilestones })
                                                }} />
                                            </div>
                                            <div className="input-container" style={{ width: "80%" }}>
                                                <p className="input-label">Stamps Required</p>
                                                <input className="text-input" type="number" value={milestone.milestone_stamps} onChange={(e) => {
                                                    let newMilestones = [...programData.milestone]
                                                    newMilestones[index].milestone_stamps = e.target.value
                                                    setProgramData({ ...programData, milestone: newMilestones })
                                                }} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="card mt-3" style={{ padding: 0, margin: 0 }}>
                        <div className="rows alignCenter justifyBetween mb-20">
                            <div class="card-header">
                                <button class="btn btn-light" type="button">
                                    <span style={{ fontSize: "1.2em", fontWeight: "bolder" }}>Policies</span>
                                </button>
                            </div>
                            <button className="backgroundLess row gap alignCenter" style={{ width: "auto" }}
                                onClick={() => {
                                    let newPolicy = { policy_name: "New Policy", policy_content: "New Policy Description", policy_active: true }
                                    if (programData?.policy) {
                                        setProgramData({ ...programData, policy: [...programData.policy, newPolicy] })
                                    } else {
                                        setProgramData({ ...programData, policy: [newPolicy] })
                                    }
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: "15%", marginLeft: "20%" }}>
                                    {<IoAddSharp size={20} />}
                                    <span style={{ marginLeft: '5px' }}>Add Policy</span>
                                </div>
                            </button>
                        </div>
                        <div className="gap" style={{ display: "flex", flexDirection: "row", }}>
                            {programData?.policy?.map((policy, index) => {
                                return (
                                    <div className="gap button-cta mt-20" style={{ display: "flex", flexDirection: "row", borderRadius: "20px" }} onClick={() => {
                                        setSelectedPolicy(policy)
                                    }}>
                                        <IoDocument />
                                        <p>{policy.policy_name}</p>
                                    </div>
                                )
                            })}
                        </div>
                        {selectedPolicy && <div style={{}}>
                            <div className="rows gap alignCenter">
                                <div className="input-container mt-20">
                                    <p className="input-label">Policy Name</p>
                                    <input className="text-input" type="text" value={selectedPolicy.policy_name} onChange={(e) => {
                                        let newPolicies = [...programData.policy]
                                        newPolicies.map((policy, index) => {
                                            if (policy.policy_name === selectedPolicy.policy_name) {
                                                newPolicies[index].policy_name = e.target.value
                                            }
                                        })
                                        setProgramData({ ...programData, policy: newPolicies })
                                    }} />
                                </div>
                                <div className="alignCenter row gap">
                                    <Switch checked={selectedPolicy.policy_active} onChange={(e) => {
                                        let newPolicies = [...programData.policy]
                                        newPolicies.map((policy, index) => {
                                            if (policy.policy_name === selectedPolicy.policy_name) {
                                                newPolicies[index].policy_active = e
                                            }
                                        })
                                        setProgramData({ ...programData, policy: newPolicies })
                                    }} />
                                    <p className="input-label">Policy Active</p>
                                </div>
                            </div>
                            <div className="input-container mt-20">
                                <p className="input-label">Policy Description</p>
                                <textarea className="text-input" type="text" style={{ height: "200px" }} value={selectedPolicy.policy_content} onChange={(e) => {
                                    let newPolicies = [...programData.policy]
                                    newPolicies.map((policy, index) => {
                                        if (policy.policy_name === selectedPolicy.policy_name) {
                                            newPolicies[index].policy_content = e.target.value
                                        }
                                    })
                                    setProgramData({ ...programData, policy: newPolicies })
                                }} />
                            </div>

                            <div className="buttonContainer" style={{ justifyContent: "right" }}>
                                <button className="button" onClick={() => {
                                    let newPolicies = [...programData.policy]
                                    newPolicies.map((policy, index) => {
                                        if (policy.policy_name === selectedPolicy.policy_name) {
                                            newPolicies[index] = selectedPolicy
                                        }
                                    })
                                    setProgramData({ ...programData, policy: newPolicies })
                                    setSelectedPolicy(null)
                                    updateConfig()
                                }}>Save Policy</button>

                                <button className="button" onClick={() => {
                                    let newPolicies = [...programData.policy]
                                    newPolicies.map((policy, index) => {
                                        if (policy.policy_name === selectedPolicy.policy_name) {
                                            newPolicies.splice(index, 1)
                                        }
                                    })
                                    setProgramData({ ...programData, policy: newPolicies })
                                    setSelectedPolicy(null)
                                    updateConfig()
                                }}>Delete Policy</button>
                            </div>
                        </div>}
                    </div>
                    <div className="buttonContainer mt-20 right">
                        <button className="button" onClick={() => {
                            updateConfig()
                        }}>Save</button>
                    </div>
                </div>
            </div>
        </MerchantLayout>
    )
}
