import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import MerchantHeader from '../../components/MerchantHeader'
import MerchantNavbar from '../../components/MerchantNavbar'
import config from '../../app-config'
import { ToastContainer, toast } from 'react-toastify';
import MerchantLayout from '../../layouts/MerchantLayout'


export default function MerchantCustomerFormPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    let CUSTOMER_ID = useParams().id || state?.user_id
    let MEMBERSHIP_ID = state?.membership_id

    const [showSimilarModal, setShowSimilarModal] = useState(false)
    const [similarUserData, setSimilarUserData] = useState([]) // first_name, last_name, email, contact_number, username
    const [selectedCustomer, setSelectedCustomer] = useState({})

    const [customerData, setCustomerData] = useState(state ? state : {
        first_name: "",
        last_name: "",
        email: "",
        contact_number: "",
        username: "",
        account_remarks: "",
        date_of_birth: "",
        // from loyalty
        user_id: "",
        company_id: "",
        loyalty_points: "",
        is_forever: "",
        expiration_date: "",
        membership_join_date: "",
        renewal_date: "",
        is_deleted: "",
        is_expired: "",
    })

    const addCustomer = async () => {
        let endpoint = config.endpoint + "/customer/admin/"
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...customerData,
            })
        })
        let response = await fetchResponse.json()
        if (response.status === 203) {
            // setShowSimilarModal(true)
            // setSimilarUserData(response.data)
            toast.error("Similar users found")
        } else if (response.status === 200) {
            toast.success("Success!")
            navigate(-1)
        } else {
            toast.error("Unable to add customer " + response.message)
        }
    }

    const updateCustomer = async () => {
        let endpoint = config.endpoint + "/customer/admin/"
        let fetchResponse = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...customerData,
            })
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Success!")
            navigate(-1)
        } else {
            toast.error("Unable to update customer " + response.message)
        }
    }

    const deleteCustomer = async () => {
        if (!MEMBERSHIP_ID) return alert.error("Unable to delete customer")
        let endpoint = config.endpoint + "/customer/admin/" + MEMBERSHIP_ID
        let fetchResponse = await fetch(endpoint, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...customerData,
            })
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Success!")
            navigate(-1)
        } else {
            toast.error("Unable to delete customer " + response.message)
        }
    }

    const enrollCustomer = async () => {
        let endpoint = config.endpoint + "/customer/enroll/"
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...selectedCustomer,
                company_id: state?.company_id,
            })
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Success, customer enrolled!")
            navigate(-1)
        } else {
            toast.error("Unable to enroll customer " + response.message)
        }
    }

    const tidyDate = (date) => { // Tue May 09 2023 23:01:33 GMT+0800 (Singapore Standard Time)NaN
        let dateObj = new Date(date)
        let year = dateObj.getFullYear()
        let month = dateObj.getMonth() + 1
        let day = dateObj.getDate()
        if (month < 10) month = "0" + month
        if (day < 10) day = "0" + day
        console.log(`${day}-${month}-${year}`)
        return `${year}-${month}-${day}`
    }

    return (
        <>
            {showSimilarModal && similarUserData.length > 0 &&
                <div>
                    <div className="modal-container">
                        <div className="card modal-content">
                            <p className="modal-title mb-10">Similar Users</p>
                            <div className="modal-body">
                                {similarUserData.map((user, index) => {
                                    return (
                                        <div className="mb-20" key={index} style={{ width: "100%", cursor: "pointer", border: selectedCustomer.id === user.id ? "2px solid green" : "1px solid #ccc", padding: "10px", borderRadius: "5px" }}
                                            onClick={() => {
                                                if (selectedCustomer.id === user.id) {
                                                    setSelectedCustomer({})
                                                } else {
                                                    setSelectedCustomer(user)
                                                }
                                            }}
                                        >
                                            <p className="text">{user.first_name} {user.last_name}</p>
                                            <p className="text" style={{ fontSize: 12, color: "gray" }}>{user.email} • {user.contact_number}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="buttonContainer" style={{ width: "100%", justifyContent: "right" }}>
                                <button className="button" onClick={() => setShowSimilarModal(false)}>Close</button>
                                {selectedCustomer?.id && <button className="button" onClick={() => {
                                    enrollCustomer()
                                }}>Enroll</button>}
                            </div>
                        </div>
                    </div>
                </div >}
            <MerchantLayout navSelected={"Customer"}>
                <div className="component">
                    <h2 className="component-title" style={{ }}>{CUSTOMER_ID === "register" ? "Add new customer" : "Edit Customer"}</h2>
                </div>
                <div className="container">
                    <div className="card mt-4 mb-3">
                        <h3 className="card-title">Customer Information</h3>
                        <div className="card-body">
                            <div className="row gap-2 mb-3">
                                <div className="col">
                                    <label htmlFor="" className="input-label">First Name</label>
                                    <input type="text" className="form-control" placeholder="First Name" value={customerData.first_name} onChange={(e) => setCustomerData({ ...customerData, first_name: e.target.value })} />
                                </div>
                                <div className="col">
                                    <label htmlFor="" className="input-label">Last Name</label>
                                    <input type="text" className="form-control" placeholder="Last Name" value={customerData.last_name} onChange={(e) => setCustomerData({ ...customerData, last_name: e.target.value })} />
                                </div>
                            </div>
                            <div className="row gap">
                                <div className="input-container">
                                    <label htmlFor="" className="input-label">Email</label>
                                    <input type="text" className="form-control" placeholder="Email" value={customerData.email} onChange={(e) => setCustomerData({ ...customerData, email: e.target.value?.toLowerCase() })} />
                                </div>
                                <div className="input-container">
                                    <label htmlFor="" className="input-label">Contact Number</label>
                                    <input type="text" className="form-control" placeholder="Contact Number" value={customerData.contact_number} onChange={(e) => setCustomerData({ ...customerData, contact_number: e.target.value })} />
                                </div>
                                <div className="input-container">
                                    <label htmlFor="" className="input-label">Date of Birth</label>
                                    <input type="date" className="form-control" placeholder="Date of Birth" value={customerData.date_of_birth} onChange={(e) => setCustomerData({ ...customerData, date_of_birth: e.target.value })} />
                                </div>
                                <div className="input-container">
                                    <label htmlFor="" className="input-label">Username</label>
                                    <input type="text" className="form-control" placeholder="Username" value={customerData.username} disabled={CUSTOMER_ID !== "register"} onChange={(e) => setCustomerData({ ...customerData, username: e.target.value.toLowerCase().replaceAll(" ", "") })} />
                                </div>
                            </div>
                            <div className="d-flex justify-content-end mt-3">
                                {CUSTOMER_ID !== "register" && MEMBERSHIP_ID &&
                                    <button className="btn btn-danger me-2" onClick={deleteCustomer}>Cancel Membership</button>
                                }
                                <button className="btn btn-primary" onClick={() => {
                                    if (CUSTOMER_ID === "register") {
                                        addCustomer()
                                    } else {
                                        updateCustomer()
                                    }
                                }}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </MerchantLayout>
        </>
    )
}
