import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import './App.css';
import './globals.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import IndexPage from "./pages/IndexPage";
import AccountPage from "./pages/AccountPage";
import AdminIndexPage from "./pages/admin/AdminIndexPage";
import AdminBranchPage from "./pages/admin/AdminBranchPage";
import AdminCustomerPage from "./pages/admin/AdminCustomerPage";
import AdminServicePage from "./pages/admin/AdminServicePage";
import AdminCompanyPage from "./pages/admin/AdminCompanyPage";
import AdminCompanyFormPage from "./pages/admin/AdminCompanyFormPage";
import AdminBranchFormPage from "./pages/admin/AdminBranchFormPage";
import AdminServiceFormPage from "./pages/admin/AdminServiceFormPage";
import AdminStaffPage from "./pages/admin/AdminStaffPage";
import AdminStaffFormPage from "./pages/admin/AdminStaffFormPage";
import AdminDocumentationPage from "./pages/admin/AdminDocumentationPage";
import MerchantIndexPage from "./pages/merchant/MerchantIndexPage";
import MerchantBusinessPage from "./pages/merchant/MerchantBusinessPage";
import MerchantCompanyFormPage from "./pages/merchant/MerchantCompanyFormPage";
import MerchantBranchFormPage from "./pages/merchant/MerchantBranchFormPage";
import MerchantLoyaltyConfigurationPage from "./pages/merchant/MerchantLoyaltyConfigurationPage";
import MerchantCustomerFormPage from "./pages/merchant/MerchantCustomerFormPage";
import MerchantCustomerPage from "./pages/merchant/MerchantCustomerPage";
import MerchantRewardsFormPage from "./pages/merchant/MerchantRewardsFormPage";
import MerchantRewardsPage from "./pages/merchant/MerchantRewardsPage";
import MerchantBookingsPage from "./pages/merchant/MerchantBookingsPage";
import MerchantBookingsFormPage from "./pages/merchant/MerchantAnnouncementFormPage";
import MerchantStaffPage from "./pages/merchant/MerchantStaffPage";
import MerchantStaffFormPage from "./pages/merchant/MerchantStaffFormPage";
import MerchantServicePage from "./pages/merchant/MerchantServicePage";
import MerchantServiceFormPage from "./pages/merchant/MerchantServiceFormPage";
import MerchantAnnouncementPage from "./pages/merchant/MerchantAnnouncementPage";
import MerchantAnnouncementFormPage from "./pages/merchant/MerchantAnnouncementFormPage";
import MerchantRewardsDashboardPage from "./pages/merchant/MerchantRewardsDashboardPage";
import MerchantCardsPage from "./pages/merchant/MerchantCardsPage";
import MerchantCardsFormPage from "./pages/merchant/MerchantCardsFormPage";
import MerchantDocumentationPage from "./pages/merchant/MerchantDocumentationPage";
import COPYHEREPAGE from "./pages/merchant/COPYHERE";
// import MemberIndexPage from "./pages/member/MemberIndexPage";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/auth" element={<AccountPage type={"login"}/>} />
        <Route path="/auth/login" element={<AccountPage type={"login"}/>} />
        <Route path="/auth/register" element={<AccountPage type={"register"}/>} />




        {/* Admin */}
        <Route path="/admin" element={<AdminIndexPage/>} />
        <Route path="/admin/company" element={<AdminCompanyPage/>} />
        <Route path="/admin/company/view" element={<AdminCompanyPage/>} />
        <Route path="/admin/company/:id" element={<AdminCompanyFormPage/>} />
        <Route path="/admin/staff" element={<AdminStaffPage/>} />
        <Route path="/admin/staff/view" element={<AdminStaffPage/>} />
        <Route path="/admin/staff/:id" element={<AdminStaffFormPage/>} />
        <Route path="/admin/customer" element={<AdminCustomerPage/>} />
        <Route path="/admin/branch" element={<AdminBranchPage/>} />
        <Route path="/admin/branch/view" element={<AdminBranchPage/>} />
        <Route path="/admin/branch/:id" element={<AdminBranchFormPage/>} />
        <Route path="/admin/service" element={<AdminServicePage/>} />
        <Route path="/admin/service/view" element={<AdminServicePage/>} />
        <Route path="/admin/service/:id" element={<AdminServiceFormPage/>} />
        <Route path="/admin/documentation" element={<AdminDocumentationPage/>} />


        {/* Merchant */}
        <Route path="/merchant" element={<MerchantIndexPage/>} />

        <Route path="/merchant/business" element={<MerchantBusinessPage/>} />
        <Route path="/merchant/business/form" element={<MerchantCompanyFormPage/>} />
        <Route path="/merchant/branch/form" element={<MerchantBranchFormPage/>} />

        <Route path="/merchant/loyalty" element={<MerchantLoyaltyConfigurationPage/>} />


        <Route path="/merchant/customer/" element={<MerchantCustomerPage/>} />
        <Route path="/merchant/customer/view" element={<MerchantCustomerPage/>} />
        <Route path="/merchant/customer/:id" element={<MerchantCustomerFormPage/>} />


        <Route path="/merchant/rewards/" element={<MerchantRewardsPage/>} />
        <Route path="/merchant/rewards/dashboard" element={<MerchantRewardsDashboardPage/>} />
        <Route path="/merchant/rewards/view" element={<MerchantRewardsPage/>} />
        <Route path="/merchant/rewards/:id" element={<MerchantRewardsFormPage/>} />

        
        <Route path="/merchant/bookings/" element={<MerchantBookingsPage/>} />
        <Route path="/merchant/bookings/view" element={<MerchantBookingsPage/>} />
        <Route path="/merchant/bookings/:id" element={<MerchantBookingsFormPage/>} />
        
        
        <Route path="/merchant/staff/" element={<MerchantStaffPage/>} />
        <Route path="/merchant/staff/view" element={<MerchantStaffPage/>} />
        <Route path="/merchant/staff/:id" element={<MerchantStaffFormPage/>} />


        <Route path="/merchant/service/" element={<MerchantServicePage/>} />
        <Route path="/merchant/service/view" element={<MerchantServicePage/>} />
        <Route path="/merchant/service/:id" element={<MerchantServiceFormPage/>} />


        <Route path="/merchant/announcements/" element={<MerchantAnnouncementPage/>} />
        <Route path="/merchant/announcements/view" element={<MerchantAnnouncementPage/>} />
        <Route path="/merchant/announcements/:id" element={<MerchantAnnouncementFormPage/>} />


        <Route path="/merchant/cards/" element={<MerchantCardsPage/>} />
        <Route path="/merchant/cards/view" element={<MerchantCardsPage/>} />
        <Route path="/merchant/cards/:id" element={<MerchantCardsFormPage/>} />

        <Route path="/merchant/documentation" element={<MerchantDocumentationPage/>} />
        <Route path="/test" element={<COPYHEREPAGE/>} />
        
        {/* member */}
        {/* <Route path="/member" element={<MemberIndexPage/>} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
