import React, { useState } from 'react'
import PublicHeader from '../components/PublicHeader'
import { useNavigate } from 'react-router'
import config from '../app-config'
import { ToastContainer, toast } from 'react-toastify';

export default function AccountPage({ type }) {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [pageState, setPageState] = useState(type ? type : "login") // login, register

    const [contactNumber, setContactNumber] = useState("")
    const [email, setEmail] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")

    const navigate = useNavigate()


    const login = async () => {
        let endpoint = config.endpoint + "/auth/login"
        console.log(endpoint)
        let fetchResponse = await fetch(endpoint,{
            method:"POST",
            credentials:"include",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                username:username,
                password:password
            })
        })
        let data = await fetchResponse.json()
        if (data.status === 200){

            switch(data.data.account_type){
                case "member":
                    navigate("/member")
                    break;
                case "merchant-staff":
                    navigate("/merchant")
                    break;
                case "merchant-manager":
                    navigate("/merchant")
                    break;
                case "admin":
                    navigate("/admin")
                    break;
                case "superadmin":
                    navigate("/admin")
                    break;
                default:
                    navigate("/")
                    break;
            }
            window.localStorage.setItem("data", JSON.stringify(data.data))
            toast.success("Welcome Back")
        }else{
            toast.error("Incorrect Credentials")
        }
    }

    const createAccount = async () => {
        let endpoint = config.endpoint + "/auth/register"
        let fetchResponse = await fetch(endpoint,{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                password:password,
                email:email,
                firstName:firstName,
                lastName:lastName,
                contactNumber:contactNumber,
            })
        })
        let data = await fetchResponse.json()
        if (data.status === 200){
            toast.success("Account Created")
            setPageState("login")
        }else{
           toast.error("Unable to create account")
        }
    }
    

    return (
        <div>
            <ToastContainer />
            <PublicHeader />
            <div className="row">
                <div className="col-lg-5 justify-content-center align-item-center" style={{paddingLeft:"8%"}}>
                    <img src="/login-page-pic.jpeg" alt="" className="img-fluid" style={{marginTop: "15%"}} />
                </div>
                <div className="col-lg-6">
                    <div className="row justify-content-center">
                        {pageState === "login" && <div style={{ minWidth: "500px", marginTop:"13%",paddingLeft:"10%" }}>
                            <h1 className="headerText mt-5 mb-4" style={{fontSize:"2em"}}>Login</h1>
                            <div style={{ marginTop: 10, marginBottom: "2%" }} className='input-container'>
                                <label>Username</label>
                                <input type="text" value={username} className='text-input' placeholder='e.g. johntan' onChange={(e) => setUsername(e.target.value.toLowerCase().replaceAll(" ",""))} />
                            </div>
                            <div style={{ marginTop: 10 , marginBottom: "2%" }} className='input-container'>
                                <label>Password</label>
                                <input type="password" value={password} className='text-input' placeholder='e.g. 123456' onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <button className="backgroundLess" style={{ marginTop: 10 }}
                                onClick={() => {
                                    navigate("/auth/register")
                                    setPageState("register")
                                }}
                            >Create Account</button>

                            <div className="buttonContainer right">
                                <button className="button"
                                    onClick={() => {
                                        login()
                                    }}
                                >Login</button>
                            </div>
                        </div>}

                        {pageState === "register" && <div style={{ minWidth: "500px", marginTop:"6%",paddingLeft:"10%" }}>
                            <h1 className="headerText mt-5 mb-4" style={{fontSize:"2em"}}>Create You Account</h1>
                                <div style={{ marginTop: 10 }} className='input-container'>
                                    <label>First Name</label>
                                    <input type="text" value={firstName} className='text-input' placeholder='e.g. John' onChange={(e) => setFirstName(e.target.value)} />
                                </div>
                                <div style={{ marginTop: 10 }} className='input-container'>
                                    <label>Last Name</label>
                                    <input type="text" value={lastName} className='text-input' placeholder='e.g. Tan' onChange={(e) => setLastName(e.target.value)} />
                                </div>

                            <div style={{ marginTop: 10 }} className='input-container'>
                                <label>Email Address</label>
                                <input type="text" value={email} className='text-input' placeholder='e.g. johntan@abc.com' onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div style={{ marginTop: 10 }} className='input-container'>
                                <label>Password</label>
                                <input type="password" value={password} className='text-input' placeholder='e.g. 123456' onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <button className="backgroundLess" style={{ marginTop: 10 }}
                                onClick={() => {
                                    navigate("/auth/login")
                                    setPageState("login")
                                }}
                            >Login</button>

                            <div className="buttonContainer right">
                                <button className="button"
                                    onClick={() => {
                                        createAccount()
                                    }}
                                >Create Account</button>
                            </div>
                        </div>}
                    </div>
                </div>

            </div>
        </div>
    )
}
