import React, { useLayoutEffect, useState } from 'react'
import { AiOutlineDashboard, AiOutlineShop, AiOutlineSolution } from 'react-icons/ai'
import { FaAngleRight, FaRegBuilding, FaUsers } from 'react-icons/fa'
import { BsPeople, BsBuilding, BsChevronRight, BsArrowRight, BsPerson, BsCalendar, BsTicket, BsMegaphone, BsCreditCard2Back } from 'react-icons/bs'
import { VscTriangleRight } from 'react-icons/vsc'
import { useNavigate } from 'react-router'

export default function MerchantNavbar({ selected, }) {
    const size = 20
    const color = "white"
    const navigate = useNavigate()

    const [hoverItem, setHoverItem] = useState("")

    const [header, setHeader] = useState(document.getElementById("header")?.clientHeight)
    const [navbarWidth, setNavbarWidth] = useState(document.getElementById("navbar")?.clientWidth)
    const [selectedTop, setSelectedTop] = useState(0)

    useLayoutEffect(() => {
        setHeader(document.getElementById("header")?.clientHeight)
        setNavbarWidth(document.getElementById("navbar")?.clientWidth)
    }, [header, navbarWidth])

    const items = [
        {
            title: "Dashboard",
            icon: <AiOutlineDashboard className="navbar-icons" size={size} color={color} />,
            children: [],
            link: "/merchant"
        },
        {
            title: "My Business",
            icon: <AiOutlineShop className="navbar-icons" size={size} color={color} />,
            children: [
                {
                    title: "My Business",
                    link: "/merchant/business/form"
                },
                {
                    title: "Loyalty Program",
                    link: "/merchant/loyalty"
                },
                {
                    title: "Reward Dashboard",
                    link: "/merchant/rewards/dashboard"
                }
            ],
            link: "/merchant/business"
        },
        {
            title: "Bookings",
            icon: <BsCalendar className="navbar-icons" size={size} color={color} />,
            children: [
                {
                    title: "View Bookings",
                    link: "/merchant/bookings/view"
                },
            ],
            link: "/merchant/bookings"
        },
        {
            title: "Rewards",
            icon: <BsTicket className="navbar-icons" size={size} color={color} />,
            children: [
                {
                    title: "View Rewards",
                    link: "/merchant/rewards/view"
                },
                {
                    title: "Add Rewards",
                    link: "/merchant/rewards/register"
                },

            ],
            link: "/merchant/rewards"
        },
        {
            title: "Loyalty Cards",
            icon: <BsCreditCard2Back className="navbar-icons" size={size} color={color} />,
            children: [
                {
                    title: "View Customers' Cards",
                    link: "/merchant/cards/view"
                },
                {
                    title: "Register New Card",
                    link: "/merchant/cards/register"
                },

            ],
            link: "/merchant/cards"
        },
        {
            title: "Broadcast",
            icon: <BsMegaphone className="navbar-icons" size={size} color={color} />,
            children: [
                {
                    title: "View Announcements",
                    link: "/merchant/announcements/view"
                },
                {
                    title: "Create Announcements",
                    link: "/merchant/announcements/form"
                },

            ],
            link: "/merchant/announcements"
        },
        {
            title: "Staff",
            icon: <BsPerson className="navbar-icons" size={size} color={color} />,
            children: [
                {
                    title: "My Staff",
                    link: "/merchant/staff/view"
                },
                {
                    title: "Add Staff",
                    link: "/merchant/staff/register"
                },

            ],
            link: "/merchant/staff"
        },
        {
            title: "Customer",
            icon: <BsPeople className="navbar-icons" size={size} color={color} />,
            children: [
                {
                    title: "My Customers",
                    link: "/merchant/customer/view"
                },
                {
                    title: "Add Customer",
                    link: "/merchant/customer/register"
                },

            ],
            link: "/merchant/customer"
        },
        {
            title: "Services",
            icon: <AiOutlineSolution className="navbar-icons" size={size} color={color} />,
            children: [
                {
                    title: "View Services",
                    link: "/merchant/service/view"
                },
                {
                    title: "Add Service",
                    link: "/merchant/service/register"
                },

            ],
            link: "/merchant/service"
        },
    ]

    return (
        <div className='navbar-main' id='navbar'
            onMouseLeave={() => {
                setHoverItem("")
            }}
            style={{
                height: '100vh',
                // width: '70px', /* Fixed width */
                padding: 10,
                paddingTop: 50
            }}
        >


            {items.map((item, index) => {
                return (
                    <div className='navbarItem' key={index} style={{ backgroundColor: selected === item.title ? "#0283EF" : "", marginBottom: '3px' }} id={`navitem-${index}`}

                        onMouseEnter={() => {
                            setHoverItem(index)
                            const navItem = document.getElementById(`navitem-${index}`);
                            if (navItem?.getBoundingClientRect()?.top) {
                                let top = navItem.getBoundingClientRect().top;
                                const dropdownHeight = 200; // Approximate dropdown height. Adjust as needed.
                                if (top + dropdownHeight > window.innerHeight) {
                                    top = window.innerHeight - dropdownHeight;
                                }
                                setSelectedTop(top);
                            }
                        }}
                    >
                        <div className='navbarItemIcon'
                            onClick={() => {
                                navigate(item.link)
                            }}
                        >
                            {item.icon}
                        </div>
                        {hoverItem === index && <div className='navbar-menu' style={{ left: navbarWidth, top: selectedTop }}>
                            <p className="" style={{ fontSize: 12, color: "black", fontWeight: "bold", padding: 10, textAlign: "center" }}>{item.title.toUpperCase()}</p>
                            {item.children.map((child, childIndex) => {
                                return (
                                    <div className='navbarItemChild' key={childIndex} style={{ marginTop: 10 }} id={`navitem-${index}-${childIndex}`}>
                                        <div className="d-flex align-items-center w-100 justify-content-between"
                                            style={{ cursor: "pointer", zIndex: 3 }}
                                            onClick={() => {
                                                navigate(child.link)
                                            }}
                                        >
                                            <p className="d-flex align-item-center" style={{ fontSize: 15, color: "black", marginLeft: "3%", marginRight: "3%", padding: 0, margin: 0 }}>{child.title}</p>
                                            <FaAngleRight color='black' style={{ marginRight: "6%" }} size={20} />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>}
                    </div>
                )
            })}
        </div>
    )
}
