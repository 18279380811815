import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ToastContainer, toast } from 'react-toastify';
import config from '../../app-config'
import MerchantHeader from '../../components/MerchantHeader'
import MerchantNavbar from '../../components/MerchantNavbar'
import MerchantLayout from '../../layouts/MerchantLayout';


export default function COPYHEREPAGE() {
    const navigate = useNavigate()
    const { state } = useLocation()

    return (
        <MerchantLayout>
            {Array.from({length:100}).map(i=>{
                return(
                    <p>Hello</p>
                )
            })}
        </MerchantLayout>
    )
}
