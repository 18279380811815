import React, { useEffect, useState } from 'react'
import MerchantHeader from '../../components/MerchantHeader'
import MerchantNavbar from '../../components/MerchantNavbar'
import { useLocation, useNavigate } from 'react-router'
import { ToastContainer, toast } from 'react-toastify';
import config from '../../app-config'
import MerchantLayout from '../../layouts/MerchantLayout';


export default function MerchantBusinessPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [businessData, setBusinessData] = useState("")

    useEffect(() => {
        fetchBusinessData()
    }, [])

    const fetchBusinessData = async () => {
        let endpoint = config.endpoint + "/company/profile"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setBusinessData(data.data)
        } else {
            toast.error(data.message)
        }
    }


    return (
        <MerchantLayout navSelected={"My Business"}>
            <div className="d-flex justify-content-between align-items-center component">
                <h2 style={{ margin: 0, padding: 0 }} className='fs-5'>{businessData?.company?.name}</h2>
                <button className="btn btn-light"
                    style={{}}
                    onClick={() => {
                        navigate("/merchant/business/form")
                    }}
                >Edit</button>
            </div>
            <div className="container">
                <div className="row justify-content-between mt-3" style={{ padding: 0, margin: 0 }}>
                    <div className="card mb-4 mt-4">
                        <h3 className="card-title mb-4">Basic Information</h3>
                        <div className="row">
                            <div className="col-lg-6 mb-4">
                                <h6>Admin Email</h6>
                                {businessData?.company?.admin_email?.map((email, index) => {
                                    return (
                                        <p key={index}>{email}</p>
                                    )
                                })}
                            </div>
                            <div className="col-lg-6 mb-4">
                                <h6>Admin Mobile</h6>
                                {businessData?.company?.admin_mobile?.map((mobile, index) => {
                                    return (
                                        <p key={index}>{mobile}</p>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                {businessData?.branches?.map((branch, index) => {
                    return (
                        <div className="card mt-3">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h5 className="card-title">Branch {index + 1}: {branch.name}</h5>
                                <button className="btn btn-primary"
                                    onClick={() => {
                                        navigate("/merchant/branch/form", { state: branch })
                                    }}
                                >Edit</button>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col">
                                    <h6>Address</h6>
                                    <p>{branch.address_line_1}</p>
                                    <p>{branch.address_line_2}</p>
                                    <p>{branch.unit_number}</p>
                                    <p>{branch.country} {branch.postal_code}</p>
                                    {/* <div className="d-flex align-items-center mt-2"> */}
                                        <h6>Website</h6>
                                        <a href={branch.website} target="_blank">{branch.website}</a>
                                    {/* </div> */}
                                </div>
                                <div className="col">
                                    <h6>Telephone</h6>
                                    <p>{branch?.telephone}</p>
                                    <h6>Email</h6>
                                    <p>{branch?.email}</p>
                                    <h6>Fax</h6>
                                    <p>{branch?.fax}</p>
                                </div>
                                <div className="col">
                                    <h6>Operating Hours</h6>
                                    {branch?.operating_hours?.map((openingHour, index) => {
                                        return (
                                            <div className='mb-2'>
                                                <p>{openingHour?.day}</p>
                                                <p>{openingHour?.open} to {openingHour?.close}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="col">
                                    <iframe src={`${branch.map_link}`} frameborder="0"></iframe>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </MerchantLayout>
    )
}
