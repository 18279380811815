import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ToastContainer, toast } from 'react-toastify';
import config from '../../app-config'
import MerchantHeader from '../../components/MerchantHeader'
import MerchantNavbar from '../../components/MerchantNavbar'
import MerchantLayout from '../../layouts/MerchantLayout';
import { FaSearch } from 'react-icons/fa';

export default function MerchantCardsPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [query, setQuery] = useState("")

    const [cardsData, setCardsData] = useState([])

    const fetchCardData = async () => {
        let endpoint = config.endpoint + "/card/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (response.status === 200) {
            setCardsData(data.data)
        } else {
            toast.error("Unable to fetch reward data " + data.message)
        }
    }

    useEffect(() => {
        fetchCardData()
    }, [])

    return (
        <MerchantLayout navSelected={"Loyalty Cards"}>
            <div className="d-flex justify-content-between flex-row component">
                <h2 className="component-title" style={{ margin: 0, padding: 0 }}>Loyalty Cards</h2>
                <button className="btn btn-light" style={{ border: "0.5px solid grey", borderRadius: "5px", marginRight: "%" }}
                    onClick={() => navigate("/merchant/cards/register")}
                >Register new card</button>
            </div>
            <div className='container mt-4'>
                <div className='d-flex justify-content-center'>
                    <p className='align-items-center m-4 fw-bold' style={{ fontSize: "1.5em" }}>Search Loyalty Card Users</p>
                </div>
                <div className='d-flex justify-content-center'>
                    <div style={{ position: 'relative', width: '90%' }}>
                        <input type="text" className="text-input align-items-center" style={{ lineHeight: "2", width: "100%", paddingLeft: '30px', borderRadius: '20px' }} placeholder="Search loyalty card..." value={query} onChange={(e) => setQuery(e.target.value)} />
                        <FaSearch style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', opacity: '0.5' }} />
                    </div>
                </div>

                <div className="mt-5">
                    {cardsData && cardsData.length > 0 ? (
                        cardsData && cardsData.map((card, index) => {
                            if (JSON.stringify(card).toLowerCase().includes(query.toLowerCase())) {
                                return (
                                    <div className="card mt-3"> 
                                        <div className="card-body d-flex justify-content-between align-items-center">
                                            <div>
                                                <p style={{ fontSize: 16 }}>{card?.user_data?.first_name} {card?.user_data?.last_name} - {card?.card_name}</p>
                                                <p style={{ fontSize: 12, color: "gray" }}>{card.points} stamps</p>
                                            </div>
                                            <button className="btn btn-light"
                                                onClick={() => navigate("/merchant/cards/" + card.id, { state: card })}
                                            >Edit</button>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    ) : (
                        <div className='d-flex justify-content-center align-items-center' style={{ marginTop: "5%", fontSize: "1.1em" }}>
                            <p>No Loyalty Cards created</p>
                        </div>
                    )}

                </div>
            </div>
            
        </MerchantLayout>
    )
}
