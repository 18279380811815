import React, {useEffect, useState} from 'react'
import AdminHeader from '../../components/AdminHeader'
import AdminNavbar from '../../components/AdminNavbar'
import { useLocation, useNavigate } from 'react-router'
import AdminLayout from '../../layouts/AdminLayout';
import config from '../../app-config'


export default function AdminCustomerPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    return (
        <AdminLayout navSelected={"Company"}>
             <div className="container p-2 " style={{ marginTop: "3%" }}>
                <div className="container">
                    {Array(1000).fill(0).map((_, i) => (
                        <p>{i}</p>
                    ))}
                </div>
            </div>
        </AdminLayout>
    )
}
