import React, { useEffect, useState } from 'react'
import AdminHeader from '../../components/AdminHeader'
import AdminNavbar from '../../components/AdminNavbar'
import { useLocation, useNavigate, useParams } from 'react-router'
import { IoAddSharp, IoClose } from 'react-icons/io5'
import config from '../../app-config'
import { ToastContainer, toast } from 'react-toastify';
import MerchantHeader from '../../components/MerchantHeader'
import MerchantNavbar from '../../components/MerchantNavbar'
import MerchantLayout from '../../layouts/MerchantLayout'


export default function MerchantServiceFormPage() {
    const navigate = useNavigate()
    const { state } = useLocation()

    const SERVICE_ID = useParams().id || state?.id

    const [companyData, setCompanyData] = useState([])
    const [serviceData, setServiceData] = useState(state ? state : {
        id: "",
        name: "",
        company_id: "",
        price: "0",
        duration: "0",
        description: "",
        is_active: 1,
        is_deleted: 0,
    })

    const registerService = async () => {
        let endpoint = config.endpoint + "/service/"
        let fetchResponse = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",

            },
            body: JSON.stringify(serviceData)
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Success!")
            navigate("/merchant/service")
        } else {
            toast.error("Unable to register service " + response.message)
        }
    }

    const updateService = async () => {
        let endpoint = config.endpoint + "/service/"
        let fetchResponse = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",

            },
            body: JSON.stringify(serviceData)
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Success!")
            navigate("/merchant/service")

        } else {
            toast.error("Unable to update service " + response.message)
        }
    }

    const deleteService = async () => {
        if (!SERVICE_ID) return alert.error("Invalid service ID")
        let endpoint = config.endpoint + "/service/" + SERVICE_ID
        let fetchResponse = await fetch(endpoint, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Success!")
            navigate("/merchant/service")

        } else {
            toast.error("Unable to delete service " + response.message)
        }
    }


    return (
        <MerchantLayout navSelected={"Services"}>
            <div className="component">
                <p className='component-title'>{SERVICE_ID === "register" ? "Add Service" : "Edit Service"}</p>
            </div>
            <div className="container">
                <div className="row justifyBetween gap" style={{ marginTop: 10 }}>
                    <div className="card">
                        <p className="card-title mb-3">Service Details</p>
                        <div className="row gap justifyBetween">
                            <div className="input-container" style={{ width: "50%" }}>
                                <p className="input-label">Name of Service</p>
                                <input className="text-input" type="text" placeholder="Service name" value={serviceData?.name} onChange={(e) => setServiceData({ ...serviceData, name: e.target.value })} />
                            </div>
                            {/* <div className="input-container" style={{ width: "30%" }}>
                                        <p className="input-label">Company</p>
                                        <select
                                            value={serviceData.id}
                                            label="Select Company"
                                            className='select-input'
                                            onChange={(e, value) => {
                                                setServiceData({ ...serviceData, company_id: value })
                                            }}
                                        >
                                            {companyData.map((company, index) => {
                                                return (
                                                    <option value={company.id}>{company.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div> */}
                        </div>
                        <div className="input-container">
                            <p className="input-label">Service Description</p>
                            <textarea className="textarea-input" type="text" placeholder="Remarks" value={serviceData?.description} onChange={(e) => setServiceData({ ...serviceData, description: e.target.value })} />
                        </div>
                    </div>
                </div>
                <div className="row justifyBetween gap" style={{ marginTop: 10 }}>
                    <div className="card">
                        <p className="card-title mb-3">Service Configuration</p>
                        <div className="row justifyBetween alignCenter gap">
                            <div className="input-container">
                                <label htmlFor="">Price ($)</label>
                                <input className="text-input" type="number" placeholder="Price" value={serviceData?.price} onChange={(e) => setServiceData({ ...serviceData, price: e.target.value })} />
                            </div>
                            <div className="input-container">
                                <label htmlFor="">Duration (mins)</label>
                                <input className="text-input" type="number" placeholder="Service Duration" value={serviceData?.duration} onChange={(e) => setServiceData({ ...serviceData, duration: e.target.value })} />
                            </div>
                            <div className="input-container">
                                <label htmlFor="">Status</label>
                                <select className="select-input" value={serviceData?.is_active} onChange={(e) => setServiceData({ ...serviceData, is_active: e.target.value })}>
                                    <option value={1}>Active</option>
                                    <option value={0}>Inactive</option>
                                </select>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="buttonContainer mt-3 mb-4 right">
                    {SERVICE_ID !== "register" && <button className="button" style={{ backgroundColor: "red" }} onClick={() => {
                        deleteService()
                    }}>Delete</button>}
                    <button className="button" onClick={() => {
                        if (SERVICE_ID === "register") {
                            // Register
                            registerService()
                        } else {
                            // Update
                            updateService()
                        }
                    }}>Save</button>
                </div>
            </div>
        </MerchantLayout>
    )
}
