import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { ToastContainer, toast } from 'react-toastify';
import config from '../../app-config'
import MerchantHeader from '../../components/MerchantHeader'
import MerchantNavbar from '../../components/MerchantNavbar'
import Select from 'react-select'
import MerchantLayout from '../../layouts/MerchantLayout';


export default function MerchantCardsFormPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [customerData, setCustomerData] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState()
    const [cardData, setCardData] = useState(state ? state : {
        user_id: "",
        points: 0,
        remarks: "",
        max_points: 0,
        milestone: [],
        card_issued_date: "",
        expiration_date: "",
        is_deleted: 0,
        card_name: "",
    })

    const [programData, setProgramData] = useState({
        starting_points: 0,
        points_per_visit: 0,
        points_per_dollar: 10,
        membership_duration: 365,
        membership_expiration: 0, //days since membership duration
        membership_tiers: [],// {tier_name: "Gold", tier_points: 1000,}
        is_forever: 0,
        renewable_points: 0,
        starting_stamps: 0,
        max_stamps: 12,
        milestone: [], //{milestone_points: 100, milestone_reward: "Free Drink", status: "unclaimed",}
        card_duration: 12, //months
        card_name: "Loyalty Card",
    })


    const CARD_ID = useParams().id || state?.id

    const fetchCustomerData = async () => {
        let endpoint = config.endpoint + "/customer/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setCustomerData(data.data)
            if (state) {
                setSelectedCustomer(data.data.filter((customer) => customer.id == state.user_id)[0])
            }
            // setCardData(data?.data?.[0])
        } else {
            toast.error("Unable to fetch customer data " + data.message)
        }
    }

    const fetchConfig = async () => {
        let endpoint = config.endpoint + "/loyalty/config"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setProgramData(data.data)
            if (CARD_ID === "register") {
                console.log("set", tidyDate(new Date()), tidyDate(new Date(Date.now() + data.data.membership_duration * 24 * 60 * 60 * 1000)))
                setCardData({
                    ...cardData,
                    max_points: data.data.max_stamps,
                    milestone: data.data.milestone,
                    card_issued_date: tidyDate(new Date()),
                    expiration_date: tidyDate(new Date(Date.now() + data.data.membership_duration * 24 * 60 * 60 * 1000)),
                    points: data.data.starting_stamps,
                    card_name: data.data.card_name,
                })
            }
        } else {
            toast.error("Failed to fetch loyalty program configuration")
        }
    }

    const tidyDate = (date) => {
        if (!date) return ""

        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()

        if (month < 10) month = "0" + month
        if (day < 10) day = "0" + day

        return `${year}-${month}-${day}`
    }

    const registerCard = async () => {
        let endpoint = config.endpoint + "/card/"
        let response = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...cardData,
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            navigate("/merchant/cards")
            toast.success("Card registered successfully")
        } else {
            toast.error("Unable to fetch customer data " + data.message)
        }
    }

    const updateCard = async () => {
        let endpoint = config.endpoint + "/card/"
        let response = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...cardData,
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            navigate("/merchant/cards")
            toast.success("Card updated successfully")
        } else {
            toast.error("Unable to fetch customer data " + data.message)
        }
    }

    const deleteCard = async () => {
        if (!cardData?.id) return alert.error("Card ID not found")
        let endpoint = config.endpoint + "/card/" + cardData?.id
        let response = await fetch(endpoint, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let data = await response.json()
        if (data.status === 200) {
            navigate("/merchant/cards")
            toast.success("Card deleted successfully")
        } else {
            toast.error("Unable to fetch customer data " + data.message)
        }
    }

    useEffect(() => {
        fetchConfig()
        fetchCustomerData()
    }, [])

    return (
        <MerchantLayout navSelected={"Loyalty Cards"}>
            <div className="component">
                <p className="component-title">{CARD_ID === "register" ? "Register New Card" : "Update Customer's Card"}</p>
            </div>
            <div className="container mt-20">
                <div className="row gap justifyBetween">
                    <div className="card">
                        <p className="card-title mb-20">Card Information</p>
                        <div className="input-container" >
                            <label htmlFor="" className="input-label">Client</label>
                            <Select
                                value={{ value: selectedCustomer?.id, label: `${selectedCustomer?.first_name} ${selectedCustomer?.last_name}`, payload: selectedCustomer }}
                                onChange={(e) => {
                                    setSelectedCustomer(e.payload)
                                    setCardData({
                                        ...cardData,
                                        user_id: e.value
                                    })
                                }}
                                options={customerData.map((customer) => {
                                    return (
                                        { value: customer.id, label: `${customer.first_name} ${customer.last_name}`, payload: customer }
                                    )
                                })}
                            />
                        </div>
                        <div className="input-container">
                            <label htmlFor="" className="input-label">Stamps</label>
                            <input type="number" className="text-input" value={cardData.points} onChange={(e) => {
                                setCardData({
                                    ...cardData,
                                    points: e.target.value
                                })
                            }} />
                        </div>
                        <div className="input-container" >
                            <label htmlFor="" className="input-label">Remarks</label>
                            <textarea type="text" className="textarea-input" value={cardData.remarks} onChange={(e) => {
                                setCardData({
                                    ...cardData,
                                    remarks: e.target.value
                                })
                            }} />
                        </div>
                    </div>
                    <div className="card">
                        <p className="card-title mb-20">Default Card</p>
                        <div className="input-container">
                            <label htmlFor="" className="input-label">Card Name</label>
                            <input type="text" className="text-input" value={cardData.card_name} onChange={(e) => {
                                setProgramData({
                                    ...programData,
                                    card_name: e.target.value
                                })
                            }} />
                        </div>
                        <div className="input-container">
                            <label htmlFor="" className="input-label">Card Issued Date</label>
                            <input type="date" className="text-input" value={cardData.card_issued_date} onChange={(e) => {
                                setCardData({
                                    ...cardData,
                                    card_issued_date: e.target.value
                                })
                            }} />
                        </div>
                        <div className="input-container">
                            <label htmlFor="" className="input-label">Expiration Date</label>
                            <input type="date" className="text-input" value={cardData.expiration_date} onChange={(e) => {
                                console.log(e.target.value)
                                setCardData({
                                    ...cardData,
                                    expiration_date: e.target.value
                                })
                            }} />
                        </div>
                        <div className="input-container">
                            <label htmlFor="" className="input-label">Max Stamps</label>
                            <input type="number" className="text-input" value={cardData.max_points} onChange={(e) => {
                                setCardData({
                                    ...cardData,
                                    max_points: e.target.value
                                })
                            }} />
                        </div>
                    </div>
                    <div className="card mt-10">
                        <p className="card-title mb-20">Milestone</p>
                        {programData?.milestone?.map((milestone, index) => {
                            return (
                                <div className="row gap alignCenter">
                                    <div className="input-container">
                                        <label htmlFor="" className="input-label">Milestone Points</label>
                                        <input type="number" className="text-input" value={milestone.milestone_stamps} onChange={(e) => {
                                            let newMilestone = [...programData.milestone]
                                            newMilestone[index].milestone_stamps = e.target.value
                                            setProgramData({
                                                ...programData,
                                                milestone: newMilestone
                                            })
                                        }} />
                                    </div>
                                    <div className="input-container">
                                        <label htmlFor="" className="input-label">Reward</label>
                                        <input type="text" className="text-input" value={milestone.milestone_name} onChange={(e) => {
                                            let newMilestone = [...programData.milestone]
                                            newMilestone[index].milestone_name = e.target.value
                                            setProgramData({
                                                ...programData,
                                                milestone: newMilestone
                                            })
                                        }} />
                                    </div>
                                    <div className="input-container">
                                        <label htmlFor="" className="input-label">Status</label>
                                        <Select
                                            value={{ value: milestone.status, label: milestone.status ? milestone.status : "Unclaimed" }}
                                            onChange={(e) => {
                                                let newMilestone = [...programData.milestone]
                                                newMilestone[index].status = e.value
                                                setProgramData({
                                                    ...programData,
                                                    milestone: newMilestone
                                                })
                                            }}
                                            options={[
                                                { value: "Unclaimed", label: "Unclaimed" },
                                                { value: "Claimed", label: "Claimed" },
                                            ]}
                                        />
                                    </div>
                                </div>

                            )
                        })}
                    </div>
                </div>


                <div className="buttonContainer mt-3 mb-4 right">
                    {CARD_ID !== "register" && <button className="button" onClick={() => {
                        deleteCard()
                    }}>Forfeit Card</button>
                    }
                    <button className="button" onClick={() => {
                        if (CARD_ID === "register") {
                            registerCard()
                        } else {
                            updateCard()
                        }
                    }}>{CARD_ID === "register" ? "Register Card" : "Update Card"}</button>
                </div>
            </div>
        </MerchantLayout>
    )
}
