import { Link } from "react-router-dom";

const PublicHeader = () => {
    
    return (
        <div className="d-flex justify-content-between align-items-center p-3 bg-light">
            <Link to={"/"} className="text-decoration-none text-dark">
                <div className="d-flex align-items-center gap-3">
                    <img src="/icon.png" alt="" style={{ height: "50px" }} />
                    <h4>Loyalty System Suite</h4>
                </div> 
            </Link>
            
            <Link to={"/auth/login"} className="text-decoration-none text-dark m-3"><button type="button" class="btn btn-light">Login</button></Link>
        </div>
    );
}

export default PublicHeader;